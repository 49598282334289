.pl_85 {
  padding-left: 85px;
}

.pr_15 {
  padding-right: 15px;
}

.pt_60 {
  padding-top: 60px;
}

.footer-wrapper {
  justify-content: space-between;
  margin: 0;
}

.copy-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-bold {
  font-weight: 500 !important;
}

.social-images {
  height: 42px;
  margin-right: 10px;
}

.f_bg {
  background-color: #f4fcfc;
  background-image: url("../../../assets/img/footer/bg.png");
  -webkit-box-shadow: 0px -1px 0px 0px rgba(231, 236, 246, 0.004);
  background-size: 100% 100%;
  box-shadow: 0px -1px 0px 0px rgb(231 236 246 / 0%);
  background-repeat: no-repeat;
}

.f-title {
  position: relative;
}

.f-title:after {
  content: "";
  border-bottom: 1px solid #ebeefa;
  position: absolute;
  width: 100px;
  left: 0;
  bottom: -5px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pl_85 {
    padding-left: 20px !important;
  }
}

@media (max-width: 767px) {
  .custom_footer p {
    max-width: 200px !important;
  }

  .copy-right {
    justify-content: center;
    flex-direction: column;
  }
  .pl_85 {
    padding-left: 20px !important;
  }
  .social-images {
    margin-top: 30px;
    margin-right: 0px;
    display: inline-block;
  }
  #social ul {
    display: inline-block;
  }
  #social li {
    margin-right: 44px;
  }
  .f_bg {
    background-image: none;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer_menu_list {
    text-align: center;
  }
  .f-title:after {
    left: 35%;
  }
  .f_widget h3 {
    text-align: center;
  }
  /* .footer_area_four .footer_top {
    padding-top: 0;
  } */
}

.blogs_item_image {
  width: 370px;
  max-width: 100%;
  height: 300px;
}
.blogs_item_image img {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.pp_img_loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogs_timestamp {
  font-family: "Poppins", sans-serif;
  position: absolute;
  width: 60px;
  height: 60px;
  background: #fff;
  top: 20px;
  border-radius: 5px;
  left: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 1px 30px rgba(55, 125, 162, 0.2);
  font-size: 15px;
  color: #677294;
}
.blogs_timestamp .date {
  font-weight: 500;
}
.blogs_read_more {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 13px;
  color: #051441;
  font-weight: 500px;
}
.blogs_subheader,
.recent_post_header,
.blogs_categories_header {
  font-size: 20px;
  color: #2c2c51;
  margin-bottom: 20px;
  transition: all 0.3s linear;
  line-height: 30px;
}
.blogs_subheader {
  font-weight: 600;
  cursor: pointer;
}
.blogs_category {
  color: #677294;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-transform: capitalize;
}
.blogs_tag {
  border: 1px solid #ebeefa;
  width: fit-content;
  padding: 10px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  color: #677294;
  float: left;
  cursor: pointer;
  text-transform: capitalize;
}
.blogs_tag.selected_tag,
.blogs_category.selected_category {
  background-color: #f0f8ff;
  border-color: #0000;
}
.blogs_category.selected_category {
  border-radius: 4px;
}
.blog_pagination_page.previous_page {
  transform: rotate(180deg);
}
.blog_pagination_pages {
  display: flex;
  width: 100%;
  align-items: center;
}
.blog_pagination_page {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: #adb5cc;
  font-weight: 500;
  font-size: 16px;
  background-color: #fafbff;
  border: 1px solid #e7e7f6;
  cursor: pointer;
}
.blog_pagination_page.selected {
  background-color: #7023ff;
  color: #fff;
}
.recent_post_title,
.recent_post_date {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.recent_post_image img {
  width: 90px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
}
.pp_img_loader img {
  width: 50px;
  height: 50px;
}
.recent_post_title {
  color: #051441;
  cursor: pointer;
}
.recent_post_date {
  color: #adb5cc;
}

.blog_header_subtitle {
  padding: 0 8%;
}
.blog_search_input {
  font-family: "Poppins", sans-serif;
  height: 60px;
  padding: 20px 21px;
  font-size: 15px;
}
.blog_search_input::placeholder {
  color: #adb5cc;
}
.blog_search_icon,
.blog_filter_direction {
  position: absolute;
  right: 21px;
  top: 20px;
}

.single-blog-tags {
  font-size: 16px;
  line-height: 24px;
  color: #051441;
  margin-bottom: 1rem;
}

.single-related-posts {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #051441;
  margin-bottom: 34px;
  position: relative;
}

.single-related-posts:after {
  content: "";
  border-bottom: 1px solid #ebeefa;
  width: 165px;
  bottom: -5px;
  left: 0;
  position: absolute;
}

.single-related-wrapper {
  margin-top: 44px;
}

.single-blog-img {
  border-radius: 8px;
}
.position-relative {
  position: relative;
}
.blog_page_container .single-blog-img > img {
  width: 100%;
}
@media (max-width: 767px) {
  .single-blog-tags-wrapper {
    align-items: inherit !important;
  }
  .blog_page_container {
    display: flex;
    flex-direction: column-reverse;
  }
  .blog_menu_section {
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    border: 1px solid #ced4da;
  }
  .blog_filter_menu {
    font-family: "Poppins", sans-serif;
    padding: 17px 21px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    user-select: none;
    cursor: pointer;
    color: #051441;
    font-size: 15px;
  }
  .blog_menu_item {
    margin: 10px 20px 10px 0px;
    cursor: pointer;
    font-size: 16px;
    color: #677294;
  }
  .blog_menu_item.selected {
    color: #7023ff;
    border-bottom: 1px solid #7023ff;
  }
  img.blog_filter_direction.up {
    transform: rotate(180deg);
  }
  img.blog_filter_direction.down {
    transform: rotate(0deg);
  }
}

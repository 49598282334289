.float {
  position: fixed;
  height: 60px;
  bottom: 20px;
  right: 10px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 999;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
}

.float_large {
  position: fixed;
  height: 90px;
  bottom: 20px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 999;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
  right: -20px;
}

.my-float {
  margin-top: 22px;
}

.float_telegram {
  background: linear-gradient(180deg, #2aabee 0%, #229ed9 99.26%);
  box-shadow: 0px 1px 30px rgb(55 125 162 / 20%);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  transition: 0.25s ease-in-out;
  height: 54px;
  right: 10px;
  bottom: 10px;
  width: 216px;
  overflow: hidden;
}

.float_telegram img {
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  transform: translate(0px, 0px) !important;
}

.float_scroll img {
  transform: translate(146px, 0px);
}

.float_telegram span {
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  padding-left: 10px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transform: translate(0px, 0px);
  white-space: nowrap;
}

.float_scroll {
  height: 54px;
  width: 54px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.float_scroll span {
  /* visibility: hidden; */
  transform: translate(160px, 0px);
  transition: 0.25ms ease-in-out;
  /* transform: translate(10px, 10px); */
}

.executor_pull_payment_section {
  padding: 0 0 100px;
}

.executor_pull_payment_section .top_bg img {
  width: 100%;
}

.executor_pull_payment_section .pull_payment_detail h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #000000;
  padding: 20px 0;
}

.executor_pull_payment_section .pull_payment_detail p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #677294;
}

.executor_pull_payment_section .executor_program {
  display: flex;
}

.executor_pull_payment_section .executor_program .program_detail {
  margin: auto 0;
}

.executor_pull_payment_section .executor_program .program_detail h3 {
  padding: 0 27px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: auto 0;
  background: linear-gradient(180deg, #7023ff 0%, #051441 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.top_bg_img {
  display: block;
}
.top_bg_img_mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .executor_pull_payment_section {
    padding: 0 0 50px;
  }
  .executor_pull_payment_section .pull_payment_detail h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .executor_pull_payment_section .pull_payment_detail p {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .executor_pull_payment_section .executor_program {
    padding: 0px 0;
  }
  .executor_pull_payment_section .executor_program .program_image {
    margin: auto 0;
  }
  .executor_pull_payment_section .executor_program .program_image img {
    width: 100%;
  }
  .executor_pull_payment_section .executor_program .program_detail h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding: 0 0 0 15px;
  }

  .top_bg_img {
    display: none;
  }
  .top_bg_img_mobile {
    display: block;
  }

  .pull_payment_detail h3 {
    font-size: 22px !important;
    line-height: 33px !important;
    text-align: left !important;
  }

  .pull_payment_detail p {
    text-align: left !important;
  }

  .program_detail h3 {
    font-size: 14px !important;
    line-height: 19px !important;
    font-weight: 500 !important;
  }

  .executors_img {
    border-bottom: 1px solid #ebeefa;
    padding-bottom: 50px;
  }
}

.card_flip {
  margin: 35px 0;
}

.card_front,
.card_back {
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #7023ff;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 0 30px 10px #ac73ff38;
}

.card_back_content,
.card_front_content {
  padding: 20px 30px;
  position: relative;
}

.card_coming_soon,
.card_v3 {
  margin: 0;
}

.img_v3 {
  position: absolute;
  top: 0;
  right: 15px;
  width: 30px;
}

.img_v3_invert {
  position: absolute;
  top: 0;
  left: 15px;
  width: 30px;
}

.img_coming_soon {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}

.card_detail {
  display: flex;
  align-items: flex-start;
}

.card_detail_img {
  margin-right: 20px;
}

.card_detail_content .h_head {
  margin-bottom: 10px;
}

.img_coming_soon_invert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}

.liquidity_special_section {
  background-image: unset;
}

.liquidity_special_section .outlet_title {
  padding: 0px 319px;
}

.liquidity_special_section .features_info_steps .agency_featured_item,
.liquidity_pools_section .features_info_steps .agency_featured_item {
  margin-top: 50px;
}

.liquidity_special_section
  .features_info_steps
  .agency_featured_item
  .agency_featured_content
  p {
  padding-right: 118px;
}

.liquidity_pools_section {
  background-image: unset;
  padding: 40px 0;
}

.liquidity_pools_section .outlet_title {
  padding: 0px 256px;
}

@media screen and (max-width: 767px) {
  .liquidity_special_section {
    padding: 0;
  }
  .liquidity_special_section .outlet_title,
  .liquidity_pools_section .outlet_title {
    padding: 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin: 0;
  }
  .liquidity_special_section
    .features_info_steps
    .agency_featured_item
    .agency_featured_content
    p {
    padding: 0;
  }
  .liquidity_special_section .features_info_steps .agency_featured_item {
    margin-top: 30px;
  }
  .liquidity_special_section
    .features_info_steps
    .agency_featured_item_two
    .agency_featured_content,
  .liquidity_pools_section
    .features_info_steps
    .agency_featured_item_two
    .agency_featured_content {
    text-align: right !important;
  }
}

.agency_featured_item .pumapay_wallet_content .step-title {
  color: #051441;
  padding: 0;
  font-size: 41px;
  font-weight: 500;
  line-height: 54px;
  padding-bottom: 30px;
}

.agency_featured_item .pumapay_wallet_content .step-description {
  font-size: 20.066px;
  line-height: 30px;
  color: #051441;
  padding: 0;
  padding-bottom: 30px;
  max-width: 398px;
}

.agency_featured_item .pumapay_wallet_content .download-text {
  font-size: 20px;
  line-height: 30px;
  color: #051441;
  padding-bottom: 20px;
}

.agency_featured_item .agency_featured_img .step-title-mobile {
  display: none;
}

@media (max-width: 767px) {
  .wallet_mobile {
    text-align: center;
  }

  .pumapay_wallet_content {
    padding: 30px 30px 100px 30px !important;
    display: table !important;
    width: 100%;
  }

  .wallet_mobile .action_btn {
    text-align: center;
    display: table !important;
    margin: auto;
  }

  .agency_featured_item .agency_featured_img .step-title-mobile {
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 33px !important;
    color: #051441;
    padding: 0 40px;
  }

  .agency_featured_item .wallet_mobile .step-description {
    font-size: 14px;
    line-height: 21px;
    color: #677294;
  }

  .agency_featured_item .wallet_mobile .download-text {
    font-size: 14px;
    line-height: 21px;
    color: #051441;
  }

  .agency_featured_item .wallet_mobile .step-title {
    display: none;
  }

  .agency_featured_item .agency_featured_img .step-title-mobile {
    display: block;
  }
}

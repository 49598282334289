.announcing_banner {
  cursor: pointer;
  transition: transform 0.3s ease 0s;
  transform: rotate(-1deg) scale(0.98);
}
.announcing_banner:hover {
  transform: rotate(0deg);
}
.announcing_banner_bg {
  display: block;
  width: 100%;
}
.announcing_banner_bg_mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .announcing_banner {
    transition: none;
    transform: rotate(0deg) scale(0.98);
  }
  .announcing_banner_bg {
    display: none;
  }
  .announcing_banner_bg_mobile {
    display: block;
    width: 100%;
  }
}

.success_res {
  float: left;
  display: block;
  line-height: 21px;
  color: green;
  margin: 3px 0;
}

.error_res {
  float: left;
  display: block;
  line-height: 21px;
  color: red;
  margin: 3px 0;
}

.res_msg {
  margin: 17px;
}

.recaptcha_message {
  font-size: 14px;
  line-height: 21px;
  display: table;
  width: 100%;
  clear: both;
  padding-top: 20px;
}

@media (max-width: 767px) {
  .res_msg {
    margin: 3px ​0px !important;
  }
  .btn_three {
    width: 100%;
  }
}

.slider_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */
  color: #051441;
  text-align: center;
  margin-bottom: 25px;
}

.custom_indicators {
  margin: 0;
  bottom: -10px;
}

.custom_indicators li {
  background: #adb5cc;
  border-radius: 150px;
  width: 15px;
  height: 15px;
  border: 0px;
}

.custom_indicators li.active {
  background: #7023ff;
  width: 42px;
}

.bg_img {
  background-image: url("../../../assets/img/forex/background1.png");
}

.slider_desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  /* Black 2 */
  color: #677294;
}

.custom_head {
  margin-top: 60px;
}

.forex_carousel_desc {
  display: flex;
  flex-direction: column;
}

.forex_carousel_desc span {
  padding: 0;
}

.forex_carousel_desc span a {
  color: #7023ff;
}

.forex-carousel-slide1 .agency_featured_item {
  margin: 0;
}

.forex-carousel-slide1 .img_v3 {
  width: 60px;
}

.forex-carousel-slide1 .img_coming_soon {
  width: 170px;
}

.forex-carousel-slide1 .card_front,
.forex-carousel-slide1 .card_back {
  height: 630px;
}

@media (max-width: 767px) {
  .slider_header {
    font-size: 22px !important;
    line-height: 33px !important;
    font-weight: 500 !important;
  }
  .slider_desc {
    font-size: 14px !important;
    line-height: 21px;
  }
  .bg_img {
    padding-bottom: 140px !important;
    background-size: 317% 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-position: bottom;
  }
  .forex_carousel_section .custom_head {
    margin: 40px 0;
  }
  .forex_carousel_section .carousel .carousel-inner .custom_caraousel {
    flex-direction: column-reverse !important;
    margin-top: 0 !important;
  }
  .forex_carousel_section
    .carousel
    .carousel-inner
    .custom_caraousel
    .agency_featured_img {
    margin-top: 40px;
  }
  .forex-carousel-slide1 .card_front,
  .forex-carousel-slide1 .card_back {
    height: 860px;
  }
  .forex-carousel-slide1 .forex_carousel_desc span a {
    word-break: break-all;
  }
  .forex-carousel-slide1 .card_back .agency_featured_img img {
    max-width: 115%;
  }
}

.crypto_hosting_banner_area {
  background: #fff;
  background-size: 110% 100%;
  background-position: top;
  padding-top: 130px;
  background-image: url("../../../assets/img/paywithcrypto/paywithcryptoBG.png");
}

.crypto_text {
  margin-left: 180px;
  text-align: left;
}

.header_first {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  color: #051441;
  max-width: 445px;
}

.desc_first {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #677294 !important;
}

.banner_color {
  color: #051441;
}

@media (max-width: 767px) {
  .crypto_hosting_banner_area {
    background-image: url("../../../assets/img/paywithcrypto/banner_mobile.png");
    text-align: center;
    padding-top: 100px;
    align-items: center;
    display: block !important;
  }

  .header_first {
    font-size: 28px;
    text-align: center !important;
    color: #fff;
    line-height: 38px;
    font-weight: 500;
  }

  .use-crypto-banner-img {
    margin-top: 30px;
  }

  .desc_first {
    font-size: 14px;
    text-align: center !important;
    color: #fff !important;
  }

  .banner_color {
    color: #fff;
  }
}

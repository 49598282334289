.languge_img {
  padding: 0 13px 0 0;
}

.languageDrop .dropdown-toggle {
  display: flex;
}

.languageDrop .dropdown-toggle::after {
  border: 0;
  background-image: url("../../../assets/img/language/iconClose.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: 7px;
  left: 10px;
}

.languageDrop .dropdown.show .dropdown-toggle:after {
  background-image: url("../../../assets/img/language/iconOpen.svg");
}

.languageDrop .dropdown-menu {
  left: inherit;
  right: 0;
  background: #003cb3;
  border: 0;
  box-shadow: 0px 20px 24px rgba(0, 11, 40, 0.102);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.languageDrop .dropdown-menu li {
  cursor: pointer;
}

.adult_header .languageDrop,
.contact_header .languageDrop,
.info_header .languageDrop,
.solutions_header .languageDrop {
  color: #fff;
}

.languageDrop .dropdown-toggle::after,
.languageDrop .dropdown-toggle::after,
.languageDrop .dropdown-toggle::after,
.languageDrop .dropdown-toggle::after {
  background-image: url("../../../assets/img/language/iconCloseWhite.svg");
}
.languageDrop .dropdown.show .dropdown-toggle:after,
.languageDrop .dropdown.show .dropdown-toggle:after,
.languageDrop .dropdown.show .dropdown-toggle:after,
.languageDrop .dropdown.show .dropdown-toggle:after {
  background-image: url("../../../assets/img/language/iconOpenWhite.svg");
}
.navbar_fixed .languageDrop .dropdown-toggle::after,
.navbar_fixed .languageDrop .dropdown-toggle::after,
.navbar_fixed .languageDrop .dropdown-toggle::after,
.navbar_fixed .languageDrop .dropdown-toggle::after {
  background-image: url("../../../assets/img/language/iconClose.svg");
}
.navbar_fixed .adult_header .languageDrop .dropdown.show .dropdown-toggle:after,
.navbar_fixed .info_header .languageDrop .dropdown.show .dropdown-toggle:after,
.navbar_fixed
  .contact_header
  .languageDrop
  .dropdown.show
  .dropdown-toggle:after,
.navbar_fixed
  .solutions_header
  .languageDrop
  .dropdown.show
  .dropdown-toggle:after {
  background-image: url("../../../assets/img/language/iconOpen.svg");
}
.navbar_fixed .adult_header .languageDrop,
.navbar_fixed .contact_header .languageDrop,
.navbar_fixed .info_header .languageDrop,
.navbar_fixed .solutions_header .languageDrop {
  color: #212529;
}
@media (max-width: 767px) {
  .adult_header .languageDrop .dropdown-toggle::after,
  .contact_header .languageDrop .dropdown-toggle::after,
  .info_header .languageDrop .dropdown-toggle::after,
  .solutions_header .languageDrop .dropdown-toggle::after {
    background-image: url("../../../assets/img/language/iconClose.svg");
  }
  .adult_header .languageDrop .dropdown.show .dropdown-toggle:after,
  .contact_header .languageDrop .dropdown.show .dropdown-toggle:after,
  .info_header .languageDrop .dropdown.show .dropdown-toggle:after,
  .solutions_header .languageDrop .dropdown.show .dropdown-toggle:after {
    background-image: url("../../../assets/img/language/iconOpen.svg");
  }
  .adult_header .languageDrop,
  .contact_header .languageDrop,
  .info_header .languageDrop,
  .solutions_header .languageDrop {
    color: #212529;
  }
}

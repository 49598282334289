.timer_title {
  font-weight: 400;
  font-size: 29px !important;
  line-height: 54px !important;
  color: #fff !important;
  margin-top: 40px;
}

.comingsoon_bg {
  padding: 10px 80px 0px 0px;
  background: #fff;
  border: 1px #adb5cc solid;
  position: relative;
  overflow: hidden;
  background: #45108a;
  border-radius: 25px;
}

.timer {
  font-family: Poppins;
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7023ff;
  padding: 0px 0 15px;
}

.timer_sub {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 36.7826px;
  line-height: 55px;
  text-align: center;
  /* Black 2 */
  color: #677294;
  margin-bottom: 65px;
}

.timer_telegram_btn {
  text-align: center;
}

.link_custom {
  font-size: 20px !important;
}

/* @media (max-width: 767px) { */

.coming_soon_shape {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 26%;
}

.coming_soon_shape img {
  max-width: 240px;
}

@media screen and (max-width: 767px) {
  .coming_soon_shape {
    max-width: 120px;
  }
  .timer_telegram_btn .pay_btn.pay_btn_two {
    font-size: 16px !important;
    padding: 10px 50px;
    margin: 60px 0 15px 0 !important;
    color: #7023ff;
    line-height: 24px;
    background-color: #ffffff;
  }
  .comingsoon_bg {
    padding: 0 15px 20px 15px !important;
    background-size: 100% 100% !important;
    border-radius: 0;
  }
  .timer_title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px !important;
    line-height: 33px !important;
    padding: 15px 0 50px !important;
    margin: 0;
    text-align: center;
  }
  .timer_title br {
    display: none;
  }
  .timer_title span {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .timer {
    font-size: 30px !important;
    line-height: 46px !important;
    padding: 0px 25px !important;
  }
  .timer_sub {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px !important;
  }
  .bg_img {
    height: 100% !important;
    background-size: 100% 100%;
    padding: 20px !important;
  }
  .link_custom {
    font-size: 14px !important;
  }
  .coming_soon_shape img {
    max-width: 100%;
  }
}

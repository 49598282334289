.navbar_fixed .adult_page img {
  filter: inherit;
}

.adult-ent-header h1,
.adult-ent-header h2 {
  margin: 0 auto 30px;
  color: #051441;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
}

.adult-ent-header h2 {
  margin: 0 auto 30px;
  color: #051441;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
}

.pumapay-comment h2 {
  margin-bottom: 20px !important;
}

.solutions_for_adult_section,
.recurring-item {
  font-family: "Poppins";
}

.recurring-item {
  padding: 50px 20px;
}

.solutions_for_adult_title {
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 30px i !important;
}

.solutions_for_adult_header {
  color: #051441;
}

.solutions_for_adult_title,
.solutions_for_adult_description {
  color: #677294;
}

.hosting_service_item,
.benefits-item,
.complex-billing-item {
  padding: 20px 14px;
}

.benefits-item {
  display: flex;
}

.benefits-item-with-border h4 {
  line-height: 22px;
}
.benefits-item-with-border h4:lang(ja) {
  width: 80%;
}
.benefits-item-with-border p:lang(ja) {
  width: 85%;
}
.benefits-item-with-border {
  border: 1px solid #7023ff;
  border-radius: 8px;
  background: url(../../assets/img/card-flip/coming_soon.png);
  background-repeat: no-repeat;
  background-position: right 0px;
}
.benefits-item-with-border:lang(ja) {
  border: 1px solid #7023ff;
  border-radius: 8px;
  background: url(../../assets/img/japanese/card-flip/coming_soon.png);
  background-repeat: no-repeat;
  background-position: right 0px;
}
.adult_entertainment_flip_card .card_detail_img {
  margin-right: auto;
}

.adult_entertainment_flip_card .img_coming_soon {
  width: auto;
  z-index: 999;
}
.adult_entertainment_flip_card {
  margin: 0;
}
.adult_entertainment_flip_card .card_v3 img {
  width: auto;
  z-index: 9999;
}
.adult_entertainment_flip_card .card_detail_content {
  padding-right: 65px;
}
.adult_entertainment_flip_card img.img_v3 {
  width: 48px;
}
.adult_entertainment_flip_card img.pumapay-business-item-image {
  width: 100%;
}

.adult_entertainment_flip_card .card_back_content,
.adult_entertainment_flip_card .card_front_content {
  padding: 0px;
  z-index: 99;
}

.adult_entertainment_flip_card .card_front,
.adult_entertainment_flip_card .card_back {
  box-shadow: 0px 23px 24px -7px #ac73ff38;
  padding-top: 20px;
}

.adult_entertainment_benefits_front_card_detail,
.adult_entertainment_benefits_back_card_detail {
  height: auto;
  min-height: 10rem;
}

.complex-billing-section {
  background: #f4fcfc;
}

.recurring-billing-model-section .adult-ent-header h2 {
  margin-bottom: 20px !important;
}

.pumapay-comment-section {
  background: url(../../assets/img/solutions-for/pumapay-comment-bg.png);
  background-size: cover;
  padding-bottom: 200px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.pumapay-comment-inner-section {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pornhub-comment-inner-section {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
}

.pornhub-comment-inner-section p {
  padding-top: 20px;
  width: 70%;
}

.pornhub-comment-by {
  margin-top: 30px;
  width: 70%;
  text-align: right;
}

.pornhub-comment-inner-section,
.pornhub-comment-inner-section h3,
.pornhub-comment-inner-section p {
  color: #fff;
}

.recurring-billing-model-images {
  position: relative;
}

.recurring-billing-model-images img {
  width: 100%;
}

.industry-log-section {
  background: linear-gradient(180deg, #f4fcfc 0%, rgba(250, 251, 255, 0) 100%);
}

img.pumapay-business-console-logo {
  width: auto;
  position: absolute;
  right: -104px;
  top: -108px;
}

.recurring-head {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #051441;
  margin-bottom: 20px;
}

.recurring-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #677294;
}

.recurring-bg1 {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -30%;
  z-index: -1;
}

.benefits-bg {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: -50%;
}

.pt-100 {
  padding-top: 100px;
}

.recurring-billing-bg01,
.recurring-billing-bg02,
.recurring-billing-bg03 {
  display: none;
}

.adult_header_banner .solutions_for_adult_title {
  line-height: 24px;
  font-weight: 400;
  max-width: 500px;
  margin-bottom: 10px;
}

.adult_header_banner .solutions_for_adult_header {
  font-size: 50px;
  line-height: 70px;
  font-weight: 600;
  margin-bottom: 20px;
}

.adult_header_banner .solutions_for_adult_description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 30px;
  max-width: 500px;
}

.adult_header_banner .header_detail {
  margin-top: 118px;
}

.btn_set_up_ac_mobile {
  display: none;
}

.btn_set_up_ac_web {
  display: block;
}

@media (max-width: 768px) {
  .complex-billing-section {
    margin: 0 !important;
  }
  .adult_header_banner .solutions_for_adult_title {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
  }
  .adult_header_banner .solutions_for_adult_header {
    color: #fbfbfd;
    font-size: 28px;
    line-height: 38px;
  }
  .adult_header_banner .solutions_for_adult_description {
    color: #fbfbfd;
    font-size: 14px;
    line-height: 21px;
  }
  .adult-ent-header {
    padding: 0;
  }
  .adult-ent-header h2 {
    font-size: 22px !important;
    line-height: 33px !important;
    margin: 0 15px 30px !important;
  }
  .pornhub-comment-section img {
    padding-right: 33px;
  }
  .adult-ent-header p {
    font-size: 13px !important;
    line-height: 20px !important;
    margin-bottom: 20px !important;
  }
  .pornhub-comment-by {
    text-align: center;
  }
  .pornhub-comment-by h3 {
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  .benefits-item {
    padding: 20px 0;
  }
  .benefits-item img {
    width: 90px;
  }
  .complex-billing-item {
    padding: 5px;
  }
  .pumapay-comment-inner-section {
    text-align: center;
  }
  .pumapay-comment-section {
    padding-bottom: 394px;
    background-size: cover;
    margin: 0 !important;
  }
  .pornhub-comment-section {
    display: flex;
    justify-content: center;
  }
  img.pumapay-business-console-logo {
    width: 60%;
    right: -40px;
    top: 150px;
  }
  .recurring-billing-bg01,
  .recurring-billing-bg02,
  .recurring-billing-bg03 {
    display: block;
    position: absolute;
    z-index: -1;
    top: -22px;
  }
  img.recurring-billing-bg01 {
    width: 50%;
    left: 8px;
    top: 200px;
  }
  img.recurring-billing-bg02 {
    width: 50%;
    right: 8px;
    top: 270px;
  }
  img.recurring-billing-bg03 {
    width: 50%;
    left: 8px;
    top: 330px;
  }
  .industry-log-section img {
    width: 100%;
  }
  .pornhub-comment-inner-section p {
    width: 100%;
  }
  .hosting_service_area .container .benefits-item .h_head {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #051441;
    margin: 0 0 4px;
  }
  .hosting_service_area .container .btn_get_started_today {
    margin: 50px 0;
  }
  .recurring-billing-model-images .recurring_item2,
  .recurring-billing-model-images .recurring_item3 {
    display: flex;
    flex-direction: column-reverse;
  }
  .recurring-billing-model-images .recurring_item1:before,
  .recurring-billing-model-images .recurring_item2:before {
    content: "";
    border-bottom: 1px solid #ebeefa;
    position: relative;
    left: 0;
    width: 100%;
    top: 20px;
  }
  .recurring-billing-model-images .recurring_content {
    padding: 0;
  }
  .btn_set_up_ac_mobile {
    display: block;
    text-align: center;
    padding-bottom: 38px;
  }
  .btn_set_up_ac_web {
    display: none;
  }
  .adult_entertainment_flip_card .img_coming_soon {
    width: 100px;
  }
  .adult_entertainment_flip_card .card_detail_content {
    padding-right: 15px;
  }
  .adult_entertainment_flip_card img.img_v3 {
    width: 30px;
  }
  .benefits-item-with-border {
    background-size: 100px;
  }
  .adult_entertainment_flip_card .card_detail_img img {
    width: 90px;
  }
  .adult_entertainment_flip_card .card_detail,
  .benefits-item-with-border {
    padding-right: 25px;
  }
  .adult_entertainment_flip_card .version-three-btn .card_front {
    height: auto;
  }
  .adult_entertainment_benefits_front_card_detail,
  .adult_entertainment_benefits_back_card_detail {
    min-height: 14rem;
  }
  .recurring-billing-model-images .card_front,
  .recurring-billing-model-images .card_back {
    min-height: 49rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .pumapay-comment-section {
    padding-bottom: 100px;
  }
  .pornhub-comment-inner-section img {
    width: 64px;
  }
  .pornhub-comment-inner-section p {
    width: 75%;
  }
  .pornhub-comment-inner-section .pornhub-comment-by {
    margin-top: 10px;
    text-align: center;
  }
  .pornhub-comment-section > img {
    width: 120%;
  }
}

.custom_subbanner_h {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  /* Black 1 */
  color: #051441;
}

.custom_subbanner_p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #677294;
}

.ecosystem {
  margin-bottom: 4%;
}

.ecosystem .clients_item {
  text-align: center;
  padding: 2%;
}

.m_0 {
  margin: 0px !important;
}

.btn_start {
  margin-top: 28px;
}

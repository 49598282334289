.agency_featured_area {
  padding-top: 7%;
}

.features_info_steps {
  position: relative;
}

.features_info_steps .dot_img {
  position: absolute;
  left: 0;
  top: 55px;
  height: 90%;
  max-width: 590px;
}

.features_info_steps.feature_info_two {
  padding-bottom: 70px;
}

.accept_crypto_area_two {
  padding-bottom: 0px;
}

.features_info_steps {
  position: relative;
}

.features_info_steps.feature_info_two {
  padding-bottom: 70px;
}

.info_feature_section {
  padding-bottom: 100px;
}

.info_feature .feature_detail {
  padding-right: 130px;
}

.info_feature .info-step-description span {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px;
}

@media screen and (max-width: 767px) {
  .info_page section .container {
    padding: 30px 25px !important;
  }
  .info_feature .feature_detail {
    padding: 0;
    text-align: center;
  }
  .info_pumapay_journey .info_pumapay_journey_header {
    padding: 50px 0px;
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
  }
  .agency_featured_item .agency_featured_content p {
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
  }
  .agency_featured_item .agency_featured_content h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #051441;
  }
  .features_info_steps .info_feature {
    flex-direction: column-reverse !important;
    margin: 0;
  }
  .features_info_steps .info_feature_item1:before {
    content: "";
    border-bottom: 1px solid #ebeefa;
    left: 0;
    position: relative;
    width: 100%;
    top: 20px;
  }
}

.custom_api_section {
  background-image: url("../../../assets/img/gaming/custom_api_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.custom_api_detail {
  z-index: 9;
  padding: 60px 0;
  top: 0;
  width: 100%;
  margin: 0;
  height: 100%;
}

.custom_api_detail .title {
  font-family: "Poppins";
  line-height: 40px;
  color: #fff;
  margin-bottom: 12px;
}

.custom_api_detail p {
  font-size: 18px;
  font-family: "Poppins";
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .custom_api_detail {
    text-align: center;
  }

  .custom_api_detail .title {
    margin-top: 20px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }

  .custom_api_detail .complex_api_description {
    font-size: 16px;
    line-height: 24px;
  }
}

.error_img {
  display: flex;
  justify-content: center;
  padding: 150px 0 80px;
}

.error_img img {
  width: 50%;
}

.error_des {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #051441;
  text-align: center;
}

.back_to_home {
  text-align: center;
  padding: 50px 0 100px;
}

@media screen and (max-width: 785px) {
  .error_img {
    padding: 100px 0 80px;
  }
  .error_img img {
    width: 100%;
  }
  .error_des {
    font-size: 28px;
    line-height: 42px;
  }
  .back_to_home {
    padding: 50px 0 100px;
  }
}

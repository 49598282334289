.contact_features_area {
  padding-top: 88px;
  padding-bottom: 70px;
}

.contact-features {
  background: #fff;
  height: 100%;
  padding: 50px 15px;
}

.contact-features a h5 {
  font-weight: 400;
  margin-bottom: 24px;
}

.contact_form_box .btn_three {
  float: left;
}

.contact_form_box {
  margin-bottom: 25px;
}

.text_box {
  margin-bottom: 30px;
}

.contact-features_wrapper {
  margin-bottom: 20px;
}

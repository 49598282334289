.pt_5 {
  padding-top: 5%;
}

.p_5 {
  padding: 5%;
}

.btn_sub {
  margin: auto !important;
}

.settlement_item .griditem_p {
  margin: auto 10%;
}

.billing_item .griditem_p {
  margin: auto 16%;
}

.billing_bg {
  background-image: url("../../../assets/img/subscription/billing_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 122px;
  margin-bottom: 200px;
}

.billing_bg p {
  color: #677294;
  margin-bottom: 81px;
}

.billing_p {
  font-size: 18px;
}

.billing_title {
  color: #051441;
  max-width: 501px;
  margin: 0 auto;
  margin-bottom: 28px;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

.h_features_item {
  padding-right: 0;
}

.billing_bg .custom_detail {
  margin: 0;
}

.subs_biling_nomi .card_front,
.subs_biling_nomi .card_back {
  height: 550px;
}

.subs_biling_settle .card_front,
.subs_biling_settle .card_back {
  height: 480px;
}

.subs_biling_nomi .card_front .img_v3,
.subs_biling_settle .card_front .img_v3 {
  width: 60px;
}

.subs_biling_nomi .card_back .img_coming_soon,
.subs_biling_settle .card_back .img_coming_soon {
  width: 170px;
}

.subs_biling_settle .settlement_item .griditem_p {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .billing_bg {
    background-image: url("../../../assets/img/subscription/bg_mobile.png");
    margin-top: 50px;
    background-position: top;
    margin-bottom: 100px;
  }
  .billing_title {
    font-size: 22px;
    line-height: 33px;
    max-width: 315px;
    margin: 0 auto;
    margin: 20px 17px !important;
  }
  .billing_p {
    font-size: 13px !important;
    line-height: 19px !important;
  }
  .billing_bg p {
    margin-bottom: 50px;
  }
  .blog_list_item_two .blog_content p {
    margin-bottom: 0 !important;
  }
  .settlement_item .griditem_p,
  .billing_item .griditem_p {
    margin: 0 0;
  }
  .blog_list_item_two {
    display: flex;
    flex-direction: column-reverse;
  }
  .blog_list_item_two img {
    margin-bottom: 40px;
  }
  .blog_list_item_two .custom_detail {
    margin: 0;
  }
  .billing_custon_mobile .blog_list_item img {
    border-bottom: 1px solid #ebeefa;
  }
  .subs_biling_nomi .card_front,
  .subs_biling_nomi .card_back,
  .subs_biling_settle .card_front,
  .subs_biling_settle .card_back {
    height: 440px;
  }
  .slide_bg {
    padding: 50px 0 !important;
  }
  .subs_biling_nomi .card_front .img_v3,
  .subs_biling_settle .card_front .img_v3 {
    width: 35px;
  }
  .subs_biling_nomi .card_back .img_coming_soon,
  .subs_biling_settle .card_back .img_coming_soon {
    width: 100px;
  }
}

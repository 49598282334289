@media (max-width: 767px) {
  .action_btn {
    margin: auto;
    text-align: center;
    display: table !important;
  }

  .iphone-btn,
  .gplay-btn {
    height: 36px;
  }
}

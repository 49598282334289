.executor_partners_section {
  padding: 100px 0;
}

.executor_partners_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #051441;
  display: flex;
  flex-direction: column;
}

.executor_partners_section .partners_logs {
  padding: 50px 0 0;
}

.executor_partners_section .partners_logs img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .executor_partners_section {
    padding: 25px 0;
  }
  .executor_partners_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }
}

.partners_logos_img {
  display: block;
}

.partners_logos_img_mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .partners_logos_img {
    display: none;
  }

  .partners_logos_img_mobile {
    display: block;
  }
}

.pumapay_bridge_smart_chain {
  background: linear-gradient(180deg, #f4fcfc 0%, rgba(244, 252, 252, 0) 100%);
}

.pumapay_bridge_smart_chain .title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #051441;
  padding: 100px 0;
}

.smart_chain_row1 {
  padding: 0 0 100px;
}

.smart_chain_item {
  display: flex;
}

.smart_chain_img {
  max-width: 200px;
}

.smart_chain_content h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #051441;
}

.smart_chain_content p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #677294;
}

.smart_chain_item.item1 {
  padding-right: 74px;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .pumapay_bridge_smart_chain .title {
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
    padding: 30px;
  }
  .smart_chain_item {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    padding: 20px 0;
    margin: 0;
  }
  .smart_chain_row1 {
    padding: 0;
  }
  .smart_chain_content h5 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }
  .smart_chain_content p {
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
  }
  .pumapay_bridge_smart_chain .item1:before,
  .pumapay_bridge_smart_chain .item2:before,
  .pumapay_bridge_smart_chain .item3:before {
    content: "";
    border-bottom: 1px solid #ebeefa;
    left: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .smart_chain_item.item1 {
    padding: 0;
  }
  .pumapay_bridge_smart_chain .container {
    padding-left: 0;
  }
  .pumapay_bridge_work_container .bridge_flow {
    padding-right: 0;
    padding-left: 0;
  }
}

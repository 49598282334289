.iitem {
  margin: 30px;
}

.service_item {
  height: 451px;
  width: 371px;
  padding: 40px;
  border: 1px solid #ebeefa !important;
  box-shadow: 0px 5px 10px 0px rgb(3 115 156 / 10%) !important;
}

.iitem .service_item p {
  color: #677294;
  font-size: 16px;
  line-height: 24px;
  min-height: 140px;
}

.iitem .service_item h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.service_item img {
  margin: auto;
}

@media (max-width: 767px) {
  .iitem {
    margin: 0 !important;
  }

  .iitem .service_item h4 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .iitem .service_item p {
    color: #677294;
    font-size: 13px;
    line-height: 20px;
  }

  .service_item {
    height: 440px;
    margin: 40px 0 !important;
  }
}

.solutions_for_section,
.solutions_for_adult_section {
  display: flex;
  align-items: center;
  position: relative;
  height: 600px;
}

.solutions_for_header_image,
.solutions_for_adult_header_image {
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
}

.solutions_for_content,
.solutions_for_adult_content {
  width: 50%;
}

.solutions_for_section .container,
.solutions_for_adult_section .container {
  z-index: 999;
}

.solutions_for_adult_section {
  background-color: #fff;
  background: url(../../assets/img/solutions-for/adult-entertainmentBG.png);
  background-position: top;
  padding-top: 130px;
  background-repeat: no-repeat;
  background-size: 140% 100%;
}

.adult-banner-side {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .solutions_for_adult_section {
    background: url(../../assets/img/solutions-for/mobile/header-bg.png);
    padding: 100px 30px 60px 30px;
    height: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .solutions_for_content,
  .solutions_for_adult_content {
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }
  .solutions_for_adult_button .pp-btn-outline-primary {
    color: #f4fcfc;
    border-color: #f4fcfc;
  }
}

@media (max-width: 767px) {
  .solutions_for_adult_section .container {
    margin-bottom: 300px;
  }
  .adult-banner-side {
    display: none;
  }
  .solutions_for_adult_section {
    text-align: center;
  }
  .adult-ent-header {
    padding: 60px 0 0 0;
  }
}

.custom_nav {
  color: black !important;
}

.custom_drop {
  color: #fff;
}

.custom_drop_default {
  color: #051441;
}

.cutom_btn_header {
  border-color: #ccc5fa;
}

.navbar_fixed .custom_drop_default {
  color: #051441;
}

.navbar_fixed .custom_drop {
  color: #051441;
}

.f_w_600 {
  font-weight: 600 !important;
}

.dropdown-menu ul {
  padding: 0;
}

.business_menu {
  position: inherit !important;
}

.right_menu_bar {
  position: relative;
  display: flex;
  align-items: center;
}

.header_area .collapse {
  justify-content: flex-end;
}

.menu_bottom_border {
  width: 100px;
  border-bottom: 1px solid #ebeefa;
}

.header_btn {
  white-space: nowrap;
  text-align: center;
}

.languageDrop {
  position: absolute;
  right: 30px;
}
@media (max-width: 767px) {
  .navbar-brand {
    margin-left: 20px;
  }
  .right_menu_bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .right_menu_bar ul {
    margin: inherit !important;
  }
  .header_area .collapse {
    justify-content: flex-start;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-brand {
    margin-left: 20px;
  }
  .right_menu_bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .right_menu_bar ul {
    margin: inherit !important;
  }
  .header_area .collapse {
    justify-content: flex-start;
    padding-bottom: 20px;
  }
}

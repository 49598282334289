.info_banner {
  background-image: url("../../../assets/img/info/info_bg_banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  height: 700px;
}

.img_isometric {
  margin-top: 180px;
}

.info_custom {
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .info_banner {
    background-image: url("../../../assets/img/info/banner_info_mobile.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 115vh;
  }
  .info_title {
    font-size: 28px;
    line-height: 38px;
  }
  .info_custom {
    text-align: center;
    padding: 50px 0;
  }
  .info_desc {
    font-size: 14px;
    line-height: 21px;
  }
  .img_isometric {
    display: none !important;
  }
  .info_page .solutions_for_adult_description {
    font-size: 14px !important;
    line-height: 21px !important;
    padding: 20px 18px 10px !important;
    font-weight: 400;
  }
  .info_page .info_page_banner .container {
    padding: 10px 25px !important;
  }
}

.section_header {
  font-style: normal;
  font-size: 26px;
  line-height: 39px;

  color: #051441;
}

.ml_m7 {
  margin-left: -7% !important;
}

.speed-agility-description {
  font-size: 15px !important;
}

@media (max-width: 767px) {
  .speed-agility-description {
    font-size: 13px !important;
  }
}

.pps_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  color: #051441;
}

.custom_pps {
  padding: 0 !important;
  background: linear-gradient(
    180deg,
    #fafbff 0%,
    rgba(250, 251, 255, 0) 100%
  ) !important;
}

@media (max-width: 768px) {
  .custom_pps {
    padding-top: 50px !important;
  }
  .pps_title {
    font-size: 22px;
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

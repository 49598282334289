.pt_10 {
  padding-top: 10%;
}

.create-billing-wrapper {
  position: relative;
  bottom: -50px;
  margin-top: 54px;
}

.create_billing_model {
  border-radius: 6px;
}

.create-billing-wrapper .home_footer_content {
  text-align: center;
  z-index: 9;
  padding: 40px;
  top: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.left_telegramsection {
  text-align: left;
}

.billing_content_bg_tele {
  display: block;
  padding-left: 10%;
  text-align: left;
  background-image: url("../../../assets/img/home9/telegram_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.home {
  margin: 0% !important;
}

.home_footer_content .title {
  font-family: "Poppins";
  color: #fff;
  margin-bottom: 12px;
}

.home_footer_content p {
  font-family: "Poppins";
  color: #fbfbfd;
  margin-bottom: 26px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}

.create-billing-img {
  width: 100%;
  border-radius: 6px;
}

.billing_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #5e2ced;
  background: #fff;
  box-shadow: 0px 20px 30px 0px rgb(12 0 46 / 10%);
  border-radius: 4px;
  padding: 16px 38px;
  border: 1px solid #fff;
  transition: all 0.2s linear;
  cursor: pointer;
}

.billing_btn:hover {
  color: #5e2ced;
}

.banner_button {
  background-color: #fff;
}

.custom_banner :hover {
  background-color: #fff;
  color: #6754e2;
}

.custom_banner {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .create-billing-img {
    height: 305px;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .create-billing-img {
    height: 330px;
    object-fit: cover;
  }
  .home_footer_content .title {
    font-family: "Poppins";
    color: #fff;
    margin-bottom: 20px !important;
  }
  .home_footer_content {
    padding: 10px 20px 30px !important;
  }
  .home_footer_content p {
    font-size: 13px;
    line-height: 19px;
  }
  .billing_content_bg_tele {
    background-image: url("../../../assets/img/home9/telegram_mobile.png");
  }
  .home_footer_content {
    flex-direction: column-reverse !important;
  }
  .left_telegramsection {
    text-align: center;
  }
  .left_telegramsection p {
    font-size: 16px;
  }
  .right_telegramicon {
    max-width: 60%;
    padding-bottom: 20px;
  }
}

@media (max-width: 320px) {
  .create-billing-img {
    height: 400px;
  }
}

.right_telegramicon img {
  max-width: 100%;
}

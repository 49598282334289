.subheader-use-crypto {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #677294;
  margin: 0 3%;
}
.liquidity-use-crypto-wrapper .item-description a {
  color: #7023ff;
}
.liquidity-use-crypto-wrapper .item-description a:before {
  background: none;
  height: 0;
}

.subheader {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #677294;
  margin: 0 14%;
}

.custom_bg {
  background: linear-gradient(180deg, #f4fcfc 0%, rgba(244, 252, 252, 0) 100%);
}

.mt_50 {
  margin-top: 50px;
}

.speed-title {
  margin: 2% 25%;
}

.item1 {
  margin-right: 11%;
}

.item2 {
  margin-right: 3%;
}

.item3 {
  margin-right: 15%;
}

.game-usecase-title {
  line-height: 54px;
  color: #051441;
}

.game-usecase-wrapper {
  padding: 127px 0px 205px;
  background-image: url("../../../assets/img/gaming/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.game-usecase-wrapper .des {
  margin: auto 10%;
}

@media (max-width: 767px) {
  .game-usecase-wrapper .agency_featured_area_two .container {
    padding: 0px 0px !important;
  }
  .game-usecase-title {
    font-size: 22px;
    line-height: 33px;
  }
  .gaming-step-title {
    margin: 20px 0 !important;
    font-size: 22px !important;
  }
  .gaming-mobile-use {
    text-align: left !important;
  }

  .game-usecase-wrapper .des {
    margin: 0 !important;
    font-size: 13px;
    line-height: 21px;
  }

  .game-usecase-wrapper {
    background-image: url("../../../assets/img/gaming/game-usecase-g.png");
    padding: 40px 0px 205px;
    background-size: 100% 100%;
    background-position: top center;
    background-repeat: no-repeat;
  }
  .game-usecase-wrapper .agency_featured_area_two .agency_featured_item {
    flex-direction: column-reverse !important;
    margin: 0;
  }
}

.pumapay_bridge_main_section {
  padding: 100px 0;
  background-image: url("../../../assets/img/pumapay-bridge/bg_01.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.pumapay_bridge_container {
  padding: 0 0 120px;
}

.pumapay_bridge_container .pumapay_bridge_content h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #051441;
}

.pumapay_bridge_container .pumapay_bridge_content p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #677294;
}

.pumapay_bridge_work_container .title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #051441;
  padding: 0 0 15px;
}

.pumapay_bridge_work_container .title_desc {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #677294;
}

.pumapay_bridge_work_container .title_desc span {
  margin: 0 0 30px;
  padding: 0 94px;
}

.pumapay_bridge_work_container .bridge_flow .item1,
.pumapay_bridge_work_container .bridge_flow .item2 {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #677294;
  padding: 0 20px 50px;
}

.pumapay_bridge_work_container .bridge_flow .item1 .desc,
.pumapay_bridge_work_container .bridge_flow .item2 .desc {
  padding: 0 10px 0;
}

.pumapay_bridge_work_container .bridge_flow .item2 .desc {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.pumapay_bridge_work_container .bridge_flow .item2 .desc span {
  padding: 0 0 30px;
}

.pumapay_bridge_work_container .bridge_flow .bridge_flow_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #051441;
  text-align: center;
}

.bridge_flow_img {
  text-align: center;
  padding: 20px 0;
}

.bridge_flow_img img {
  width: 100%;
}

.bridge_flow .item1 .bridge_flow_img {
  margin: 0 0 34px;
}

.pumapay_bridge_container .pumapay_bridge_content .btn_pumapay_bridge {
  display: flex;
}

.pumapay_bridge_container
  .pumapay_bridge_content
  .btn_pumapay_bridge
  .learn-more-btn {
  color: #7023ff;
}

@media screen and (max-width: 767px) {
  .pumapay_bridge_main_section {
    padding: 50px 0;
    background-image: unset;
  }
  .pumapay_bridge_container .pumapay_bridge_content h5,
  .pumapay_bridge_work_container .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
  }
  .pumapay_bridge_container .pumapay_bridge_content p,
  .pumapay_bridge_work_container .title_desc,
  .pumapay_bridge_work_container .bridge_flow .item1 .desc,
  .pumapay_bridge_work_container .bridge_flow .item2 .desc {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
  }
  .pumapay_bridge_container .pumapay_bridge_content .btn_pumapay_bridge {
    text-align: center;
  }
  .pumapay_bridge_page .pumapay_bridge_container {
    margin: auto 0;
    padding: 0 15px !important;
  }
  .pumapay_bridge_detail {
    flex-direction: column-reverse;
  }
  .pumapay_bridge_detail img {
    margin: 25px 0;
  }
  .pumapay_bridge_work_container .title_desc span {
    margin: 0;
    padding: 10px 0;
  }
  .pumapay_bridge_work_container .bridge_flow .bridge_flow_title {
    font-size: 18px;
    line-height: 27px;
    text-align: left;
  }
  .pumapay_bridge_work_container .bridge_flow .item1 .desc,
  .pumapay_bridge_work_container .bridge_flow .item2 .desc {
    text-align: left;
  }
}

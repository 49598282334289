.agency_featured_item .agency_featured_content .crypto-step-title {
  color: #051441;
  margin-top: 30px;
}

.accept_crypto_area_two .features_info_steps .card_front {
  width: 96%;
  margin: auto;
  margin-right: 0;
  height: 412px;
}

.accept_crypto_area_two .features_info_steps .card_back {
  width: 96%;
  margin: auto;
  margin-right: 0;
  height: 412px;
}

.accept_crypto_area_two
  .features_info_steps
  .card_back
  .crypto_featured_content
  .crypto-step-description {
  color: #051441;
}

.agency_featured_item .agency_featured_content .btn_get {
  transform: none;
}

.agency_featured_item .agency_featured_content .crypto-step-description {
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #677294;
  padding: 0 !important;
}

.agency_featured_content {
  max-width: 100% !important;
}

.agency_featured_item .agency_featured_content .icon {
  display: block;
}

.agency_featured_item .agency_featured_img .steps_icon_for_mobile {
  display: none;
}

.accept_crypto_area_two .features_info_steps .card_front .img_v3 {
  width: 50px;
}

.accept_crypto_area_two .features_info_steps .card_back .img_coming_soon {
  width: 145px;
}

@media (max-width: 767px) {
  .accept_crypto_custom {
    margin-top: 40px;
    flex-direction: column-reverse !important;
  }

  .accept_crypto_area_two .features_info_steps .card_front,
  .accept_crypto_area_two .features_info_steps .card_back {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  .agency_featured_item .agency_featured_content .icon {
    display: none;
  }

  .agency_featured_item .agency_featured_img .steps_icon_for_mobile {
    display: block;
    width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 50%;
    background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
    box-shadow: 0px 10px 20px 0px rgb(94 44 237 / 20%);
    font-size: 20px;
    color: #fff;
    display: inline-block;
    text-align: center;
  }

  .features_info_steps .flex-row-reverse .steps_icon_for_mobile {
    float: left;
  }

  .features_info_steps .agency_featured_item_two .steps_icon_for_mobile {
    float: right;
  }

  .accept_crypto_custom .button_slider {
    bottom: inherit !important;
  }

  .crypto_odd_number .number {
    margin-left: 0 !important;
  }

  .accept_crypto_area_two .agency_featured_item,
  .accept_crypto_area_two .version-three-btn,
  .accept_crypto_area_two .coming-soon-btn {
    border-bottom: 1px solid #ebeefa !important;
    padding-bottom: 40px !important;
  }

  .accept_crypto_area_two .card_front .agency_featured_item,
  .accept_crypto_area_two .card_back .agency_featured_item {
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .accept_crypto_area_two .agency_featured_item::before {
    display: none;
  }
}

.adult-ent-header p {
  max-width: 1031px;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px !important;
  margin-bottom: 60px;
}

.head_detail h2 {
  margin: 0 auto 20px !important;
  font-size: 36px !important;
}

.complex-billing-item h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px !important;
  color: #051441;
}

.complex-billing-item p {
  font-size: 14px;
  line-height: 21px;
  color: #677294;
}

@media (max-width: 767px) {
  .complex_billing_btn {
    margin-bottom: 20px;
    margin-top: 21px;
  }
  .complex-billing-section .adult-ent-header p:after {
    position: absolute;
    border-bottom: 1px solid #ebeefa;
    width: 90%;
    content: "";
    left: 17px;
    margin: 35px 0;
  }
  .complex-billing-section {
    padding-bottom: 60px !important;
  }
  .complex-billing-item .item1:after,
  .complex-billing-item .item2:after {
    position: absolute;
    border-bottom: 1px solid #ebeefa;
    width: 90%;
    content: "";
    left: 17px;
    margin: 35px 0;
  }
  .complex-billing-section .btn_learn_more:after {
    position: absolute;
    border-bottom: 1px solid #ebeefa;
    width: 90%;
    content: "";
    left: 17px;
    margin: 35px 0;
    top: 75px;
  }
}

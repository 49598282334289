.other_billing_header {
  height: 500px;
}

.other_billing_header .other_billing_title {
  margin: 12% 9% 3% !important;
}

.other_billing_payment h2 {
  min-width: 572px;
}

.other_billing_payment .agency_featured_item {
  margin-top: 90px;
}

.other_billing_payment .features_info {
  padding: 0;
}

.other_billing_payment .get-started-btn {
  padding: 80px 0 100px;
}

.other_billing_speed {
  padding: 80px 0;
}

.other_billing_speed .speed-title {
  margin: 2% 23%;
}

@media screen and (max-width: 767px) {
  body .hosting_banner_area.other_billing_header {
    height: auto !important;
    min-height: 500px;
    padding: 100px 0px 60px;
  }
  .hosting_banner_area.other_billing_header .other_billing_title {
    font-size: 28px;
    line-height: 38px;
    margin: 0 auto !important;
    margin-bottom: 30px !important;
  }
  .hosting_banner_area.other_billing_header p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 auto !important;
    max-width: 315px;
  }
  .hosting_banner_area.other_billing_header .hosting_content {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .other_billing_payment h2 {
    min-width: 100%;
    margin: 0 !important;
    font-size: 22px;
    line-height: 33px;
    font-weight: 500px;
  }
  .other_billing_payment {
    padding: 50px 0;
  }
  .other_billing_payment .agency_featured_item {
    margin-top: 30px;
    flex-direction: column-reverse !important;
  }
  .other_billing_payment .agency_featured_item h1 {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin: 7px 0;
  }
  .other_billing_payment .agency_featured_item p {
    padding: 0;
    text-align: center;
    font-size: 13px !important;
    color: #677294;
    line-height: 19.5px;
    margin-bottom: 20px;
  }
  .other_billing_payment .get-started-btn {
    padding: 20px 0;
  }
  .other_billing_speed h1 {
    font-size: 22px;
    margin: 0 !important;
    line-height: 33px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    padding-bottom: 20px;
  }
  .other_billing_speed p {
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
  }
  .other_billing_speed .item1 {
    margin: 0 !important;
  }
  .other_billing_speed .item2 {
    text-align: right;
    margin: 0;
  }
  .other_billing_speed .p_service_item,
  .other_billing_speed .p_service_item .icon {
    margin: 0;
  }
  .other_billing_speed h1.section_header {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  .other_billing_footer .other_billing {
    margin: 0 !important;
    font-size: 22px !important;
    line-height: 33px;
    font-weight: 600;
    padding: 0 30px 10px;
  }
  .other_billing_footer .other_billing_des {
    margin: 0 !important;
    padding: 0px 20px 30px;
  }
  .other_billing_footer .billing_content_bg_simple {
    border-radius: 20px;
  }
  .other_billing_payment .agency_featured_item .agency_featured_content {
    margin-top: 0px;
  }
  .agency_featured_item_one {
    position: relative;
    z-index: 99;
  }
  .agency_featured_item_one > div {
    z-index: 999;
  }
  .agency_featured_item_three > div {
    z-index: 999;
  }
  .agency_featured_item_four > div {
    z-index: 999;
  }
  .agency_featured_item_three {
    position: relative;
  }
  .agency_featured_item_four {
    position: relative;
  }
  .agency_featured_item_four:after,
  .agency_featured_item_one:after {
    background-image: url("../../assets/img/otherbilling/bg_01.png");
    background-repeat: no-repeat;
    width: 221px;
    height: 417px;
    content: "";
    float: left;
    position: absolute;
    right: -10px;
    top: 0;
  }
  .agency_featured_item_three:before {
    background-image: url("../../assets/img/otherbilling/bg_02.png");
    background-repeat: no-repeat;
    width: 221px;
    height: 417px;
    content: "";
    float: left;
    position: absolute;
    left: -10px;
    top: 0;
  }
  .agency_featured_item_one:before,
  .agency_featured_item_two:before,
  .agency_featured_item_three > .agency_featured_item_img:after {
    content: "";
    border-bottom: 1px solid #ebeefa;
    width: 100%;
    position: absolute;
    left: 0;
  }
}

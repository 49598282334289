.subscription_title {
  margin: 2% 9% !important;
}

.subscription_subtitle {
  margin: 1% 17% !important;
}

.crypto_title {
  margin: 3% 12% !important;
}

.crypto_subtitle {
  margin: 1% 18% !important;
}

.topup_title {
  margin: 3% 22% !important;
}

.topup_subtitle {
  margin: 1% 9% !important;
}

.solution_title {
  margin: 12% 9% 3% !important;
}

.solution_subtitle {
  margin: 1% 8% !important;
}

.other_billing_title {
  margin: 3% 9% !important;
}

.other_billing_subtitle {
  margin: 1% 12% !important;
}

.hosting_content h1 {
  margin: auto 12%;
}

.hosting_banner_area {
  height: 500px;
}

.btn_header_banner {
  margin-top: 40px;
  text-align: center;
}

.btn_header_banner a {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .header_banner_title {
    font-size: 28px;
    line-height: 38px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-bottom: 30px;
  }
  .contact_page h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .contact_page p {
    padding: 30px;
  }
  .custom_subbanner_h {
    line-height: 33px;
    font-size: 22px;
    margin: 40px 0 !important;
  }
  .dot_img {
    display: none;
  }
  .agency_featured_item .crypto_featured_content .crypto-step-title {
    margin: 30px 0 !important;
  }
  .button_slider {
    margin: 0 !important;
    padding: 0 !important;
    bottom: 67px !important;
  }
  .agency_featured_item_two {
    text-align: right;
  }
  .agency_featured_item_two .crypto-step-description {
    padding: 0;
  }
  .billing_content {
    padding: 60px 20px !important;
  }
  .topup_title {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.02em;
    margin: 0 auto !important;
    max-width: 263px;
    margin-bottom: 30px !important;
  }
  .topup_subtitle {
    font-size: 14px !important;
    line-height: 21px !important;
    max-width: 315px;
    margin: 0 auto !important;
  }
  .topUp-mobile-1 {
    text-align: left !important;
  }
  .topUp-mobile-2 {
    text-align: right !important;
  }
  .subscription_title {
    margin: 0 15px 30px 15px !important;
    font-size: 28px;
    line-height: 38px;
  }
  .subscription_subtitle {
    margin: 0 17px !important;
  }
  .crypto_title {
    font-size: 28px;
    line-height: 38px;
    margin: auto !important;
    margin-bottom: 30px !important;
  }
  .crypto_subtitle {
    font-size: 14px !important;
    line-height: 21px !important;
    margin: 0 auto !important;
    max-width: 315px;
  }
  .hosting_content p {
    font-size: 14px;
    line-height: 21px;
  }
}

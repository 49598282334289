.executor_how_it_will_work_section {
  padding: 100px 0;
}

.executor_how_it_will_work_section .section_title h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #051441;
}

.executor_how_it_will_work_section .section_desc1 p,
.executor_how_it_will_work_section .section_desc2 p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #677294;
}

.executor_how_it_will_work_section .section_desc1 p {
  padding: 0 110px;
}

.executor_how_it_will_work_section .section_desc2 p {
  padding: 0 110px;
}

.executor_how_it_will_work_section .section_images {
  padding: 75px 0;
}

.executor_how_it_will_work_section .section_images .image_detail {
  text-align: center;
}

.executor_how_it_will_work_section .section_images .image_detail .image_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #051441;
}

.executor_how_it_will_work_section .section_images .image_detail .image1,
.executor_how_it_will_work_section .section_images .image_detail .image2,
.executor_how_it_will_work_section .section_images .image_detail .image3 {
  height: 210px;
}

@media screen and (max-width: 767px) {
  .executor_how_it_will_work_section {
    padding: 50px 0;
  }
  .executor_how_it_will_work_section .section_title h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
  }
  .executor_how_it_will_work_section .section_desc1 p,
  .executor_how_it_will_work_section .section_desc2 p {
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
  .executor_how_it_will_work_section .section_images {
    padding: 0px 0;
  }
  .executor_how_it_will_work_section .section_images .image_detail {
    padding: 30px 0;
  }
}

.how_its_work_wrapper {
  background: linear-gradient(180deg, #f4fcfc 0%, rgba(244, 252, 252, 0) 100%);
  padding-top: 100px;
  padding-bottom: 91px;
  margin-bottom: 23px;
}

.works-content {
  font-size: 16px;
  line-height: 24px;
  color: #677294;
  font-family: Poppins;
  max-width: 400px;
  padding-left: 20px;
}

.works-content.step-1 {
  padding-left: 35px;
}

.works-content.step-1 a {
  color: #7023ff;
}

.how-it-works-title h2 {
  font-family: Poppins;
  margin-bottom: 49px;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #051441;
}

@media (max-width: 767px) {
  .how_its_work_wrapper {
    padding: 60px 0;
  }
  .works-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .how-it-works-title h2 {
    margin: 0;
  }
  .how_its_work_wrapper .how_its_work_item1,
  .how_its_work_wrapper .how_its_work_item2 {
    display: flex;
    flex-direction: column-reverse;
  }
  .how_its_work_wrapper .how_its_work_item1:before {
    content: "";
    border-bottom: 1px solid #ebeefa;
    left: 0;
    position: relative;
    width: 100%;
    top: 30px;
  }
}

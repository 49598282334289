.pt_5 {
  padding-top: 5%;
}

.hiw_title {
  font-weight: 600;
}

@media (max-width: 767px) {
  .hiw_title {
    font-weight: 500;
    margin: 60px 0px 10px !important;
  }
}

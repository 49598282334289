.pay_with_crypto_coming_soon .timer_title {
  color: #051441;
}

.liquidity-use-crypto-wrapper {
  padding: 135px 0px 0px;
}

.liquidity-use-crypto-title {
  line-height: 54px;
  color: #051441;
  font-size: 36px;
}

.bg_img_liquidity {
  background-image: url("../../assets/img/paywithcrypto/liquidity-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 100px 0px 450px;
  margin-top: 138px;
}

@media screen and (max-width: 767px) {
  .liquidity-use-crypto-wrapper {
    padding-top: 40px !important;
  }

  .bg_img_liquidity {
    background-image: url("../../assets/img/paywithcrypto/liquidity-bg.png");
    background-size: 285% 102%;
    background-repeat: no-repeat;
    padding: 100px 0px 250px;
    margin-top: 30px;
  }

  .liquidity-use-crypto-title {
    font-size: 22px !important;
    line-height: 33px !important;
    font-weight: 500;
  }
}

.features_info_steps {
  position: relative;
}

.features_info_steps.feature_info_two {
  padding-bottom: 70px;
}

.topup_content .item2 .feature_detail {
  padding-left: 89px;
}

@media screen and (max-width: 767px) {
  .topup_content .item2 {
    margin-right: 0;
  }
  .topup_content .item2 .feature_detail {
    padding: 0;
  }
  .topup_content .agency_featured_item {
    margin: 0;
    flex-direction: column-reverse !important;
  }
  .topup_content .item1:before,
  .topup_content .item2:before {
    content: "";
    border-bottom: 1px solid #ebeefa;
    left: 0;
    position: relative;
    width: 100%;
    top: 30px;
  }
  .topup_content .get-started-btn {
    padding: 45px 0 50px;
  }
}

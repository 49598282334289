.h_pricing_area {
  background-image: url("../../../assets/img/solutions/Mockups.png");
  background: linear-gradient(180deg, #f4fcfc 0%, rgba(244, 252, 252, 0) 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.slider_header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */
  color: #051441;
  text-align: center;
  margin-bottom: 70px;
}

.custom_indicators {
  margin: 0;
  bottom: -10px;
}

.custom_indicators li {
  background: #adb5cc;
  border-radius: 150px;
  width: 15px;
  height: 15px;
  border: 0px;
}

.custom_indicators li.active {
  background: #7023ff;
  width: 42px;
}

.sec_pad {
  padding: 5% 0px;
}

.carousel_bg {
  background-image: url("../../../assets/img/solutions/Mockups.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  padding-top: 46px;
}

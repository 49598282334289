.game-benefit-title {
  line-height: 54px;
  color: #051441;
}

.game-benefit-wrapper {
  padding: 135px 0px 0px;
}

.service_item_gaming_wrapper {
  margin: 35px 0;
}

.service_item_gaming {
  position: relative;
}

/*.game-benefit-wrapper .version-three-btn .card_front {
  height: 300px;
}*/

.game-benefit-wrapper .version-three-btn .card_front .img_v3 {
  width: 40px;
}

.game-benefit-wrapper .service_item_gaming .card_coming_soon .img_coming_soon {
  width: 135px;
}

.game-benefit-wrapper .card_coming_soon .img_coming_soon {
  width: 135px;
}

.game-benefit-wrapper
  .service_item_gaming
  .card_coming_soon
  .card_back_content
  .benefit3 {
  margin: 0;
  min-height: 204px;
  height: auto;
}

/*.game-benefit-wrapper .coming-soon-btn .card_back {
  height: 300px;
}*/

.game-benefit-wrapper .card_flip .card_back .item-title {
  background: -webkit-linear-gradient(#7023ff, #051441);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767px) {
  .game-benefit-wrapper {
    padding-top: 80px !important;
  }
  .game-benefit-title {
    font-size: 22px;
    line-height: 33px !important;
  }
  .benefit {
    text-align: right;
  }
  .benefit1,
  .benefit3 {
    text-align: left !important;
  }
  .game-benefit-wrapper .react-card-flipper {
    min-height: 300px;
  }
}

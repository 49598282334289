.home_customer_billing {
  background-image: url("../../assets/img/home/home_billing_section_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
}
.home_page_menu .w_menu .nav-item .nav-link {
  color: #051441;
}
.home_page_menu .btn_get {
  border: 2px solid #ccc5fa;
}
.home_page_menu .sticky_logo img {
  filter: invert(39%) sepia(84%) saturate(4252%) hue-rotate(231deg)
    brightness(98%) contrast(105%);
  display: block !important;
}
.home_liquidity_provider {
  padding: 130px 0px 300px 0px;
  z-index: 999;
}
.liquidity_provider_layer {
  position: absolute;
  bottom: -14%;
}
.liquidity_provider_layer_mobile {
  display: none;
}

.home_cutomer_info .trusted_logo_bg {
  position: absolute;
  right: 0;
  top: -36%;
  z-index: -1;
}
.announcement_text_area {
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  justify-content: center;
}
.announcement_text {
  width: 85%;
  padding-top: 100px;
  text-align: center;
}
.home_liquidity_provider .text-purple-blue {
  color: #7023ff;
  padding: 0px;
}
.home_liquidity_provider .payment_features_area {
  padding-bottom: 215px;
}
.home_decentralized .payment_featured_img img {
  position: absolute;
  right: 0;
}
.liquidity_provider_right_section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
}
.liquidity_provider_right_image {
  position: absolute;
  width: 64%;
}

.deposit_liquidity_right_arrow {
  height: 50px;
  line-height: 50px;
  vertical-align: top;
  color: #7023ff;
  font-size: 14px;
  font-weight: 500;
}
.deposit_liquidity_right_arrow:hover {
  color: #7023ff;
}

.home_decentralized,
.home_cutomer_info,
.home_liquidity_provider {
  background: transparent !important;
}

.home_footer_banner {
  padding-bottom: 75px;
  padding-top: 76px;
}

.trusted_description {
  max-width: 92%;
}

.decentralized_title {
  max-width: 56%;
}

.decentralized_description {
  min-width: 589px;
}

.home_billing {
  padding: 250px 0px;
  margin-top: -100px;
}

.home_coming_soon .timer_title {
  color: #051441;
  font-size: 43.0169px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.countdown {
  display: flex;
  flex-direction: column;
  font-size: 40px;
  line-height: 50px;
  position: relative;
  font-weight: 600;
  color: #fff;
}

.countdown:after {
  content: ":";
  float: right;
  font-size: 40px;
  color: #fff;
  position: absolute;
  right: -70px;
  font-weight: 600;
}

.countdown:last-child:after {
  display: none;
}

.countdown span {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  padding-top: 20px;
}

.timer_heading {
  padding-right: 10px;
  color: #fff !important;
}

.timer_title span {
  color: #fff !important;
}

.monthy_subs {
  display: block;
}

.monthy_subs_mobile {
  display: none;
}

.client_rightarea .payment_features_content {
  max-width: 50%;
  padding: 0;
}

.payment_clients_area .payment_clients_inner .clients_item.one {
  top: 1em;
  height: 101px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.two {
  top: 16em;
  left: 4em;
  width: auto;
  min-width: 100px;
  height: 113px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.three {
  left: 10em;
  width: auto;
  min-width: 100px;
  height: 82px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.four {
  left: 14em;
  top: 24em;
  width: auto;
  min-width: 141px;
  height: 141px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.five {
  left: 28em;
  top: 19em;
  width: auto;
  min-width: 190px;
  height: 190px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.six {
  left: 18em;
  top: 10em;
  width: auto;
  min-width: 100px;
  height: 101px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.seven {
  left: 26em;
  top: 0;
  width: auto;
  min-width: 141px;
  height: 141px !important;
}

.payment_clients_area .payment_clients_inner .clients_item.eight {
  top: 9em;
  left: 34em;
  width: auto;
  min-width: 121px;
  height: 121px !important;
}

.home_customer_billing
  .payment_clients_area
  .payment_clients_inner
  .clients_item {
  height: auto;
  min-height: 80px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment_clients_area .payment_clients_inner .clients_item img {
  max-width: 100%;
  top: initial;
  transform: initial;
}

.v_3_custom {
  margin-bottom: 28px;
}

.home_learn_more_mobile {
  display: none;
}

.home_learn_more_web {
  display: inline-block;
}

/* Same height as image */
.home_decentralized_detail {
  height: 890px;
}

.home_cutomer_info .payment_clients_area {
  padding-top: 130px;
}

.announcement_section {
  background: url(../../assets/img/home/announcement_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 684px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 100px;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .home_customer_billing > section > .container {
    padding: 0px !important;
  }
  .home_coming_soon h1.timer_title {
    font-size: 20px;
    padding: 30px 5px;
    line-height: 18px;
    justify-content: center !important;
    flex-direction: column;
  }
  .decentralized_description {
    min-width: 100%;
  }
  .timer_heading {
    display: flex;
    padding: 0;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-right: 0 !important;
    align-items: center !important;
    justify-content: center;
  }
  .rocket-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rocket-img img {
    max-width: 100%;
  }
  .v_3_custom {
    margin-bottom: 14px !important;
  }
  .timer_heading img {
    max-width: 30%;
    margin: 0 !important;
  }
  .countdown {
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
  }
  .countdown:after {
    font-size: 18px;
    right: -23px;
    color: #ffffff;
  }
  .countdown span {
    padding: 0;
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
  }
  .home_customer_billing {
    padding: 100px 0px;
  }
  .home_billing {
    padding: 70px 0px;
  }
  .monthy_subs {
    display: none;
  }
  .monthy_subs_mobile {
    display: block;
    padding-top: 50px;
  }
  .payment_features_content p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 26px;
    font-weight: 400;
  }
  .payment_features_content p b {
    font-weight: 500;
  }
  .home_coming_soon {
    padding: 0;
    margin: 72px 0 !important;
  }
  .client_customrow {
    flex-direction: column-reverse;
  }
  .client_rightarea .payment_features_content {
    padding: 0;
  }
  .customer_title {
    max-width: 90%;
    font-weight: 400;
  }
  .client_rightarea .payment_features_content {
    max-width: 100%;
  }
  .payment_clients_area .payment_clients_inner .clients_item.three {
    left: 8em;
  }
  .payment_clients_area .payment_clients_inner .clients_item.seven {
    left: 15em;
    width: auto;
    min-width: 111px;
    height: 111px !important;
  }
  .payment_clients_area .payment_clients_inner .clients_item.two {
    top: 9em;
    left: 0em;
  }
  .payment_clients_area .payment_clients_inner .clients_item.six {
    left: 7em;
    top: 8em;
  }
  .payment_clients_area .payment_clients_inner .clients_item.five {
    left: 14em;
    top: 8em;
    width: auto;
    min-width: 120px;
    height: 120px !important;
    padding: 19px !important;
  }
  .payment_clients_area .payment_clients_inner .clients_item.four {
    left: 0;
    top: 19em;
  }
  .payment_clients_area .payment_clients_inner .clients_item.eight {
    top: 17em;
    left: 11em;
  }
  .payment_clients_area .payment_clients_inner {
    height: auto;
  }
  .payment_clients_area {
    padding: 0;
  }
  .home_animation {
    display: block;
  }
  .monthy_subscription_detail {
    background-image: url("../../assets/img/home9/bg_01.png");
    background-repeat: no-repeat;
    background-position: right;
  }
  .monthy_subscription .fadeInLeft {
    display: flex;
    flex-direction: column-reverse;
  }
  .comingsoon_bg .timer_title span {
    font-size: 20px !important;
    line-height: 40px !important;
  }
  .home_customer_billing .featured_item {
    flex-direction: column-reverse;
    padding: 0 25px;
  }
  .home_learn_more_mobile {
    display: inline-block;
    width: fit-content;
  }
  .home_learn_more_web {
    display: none;
  }
  .home_decentralized .featured_item {
    padding: 20px 0 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .decentralized_title {
    max-width: 100%;
  }
  .liquidity_provider_layer {
    display: none;
  }
  .liquidity_provider_layer_mobile {
    display: block;
    position: absolute;
    bottom: -50px;
  }
  .home_liquidity_provider {
    padding: 50px 0px 200px 0px;
  }

  .announcement_text_area {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  .home_cutomer_info .home_trusted_logos {
    width: 100%;
  }
  .home_decentralized_detail {
    height: auto;
  }
  .home_decentralized .payment_featured_img img {
    position: relative;
    width: 100%;
  }
  .home_page_menu.menu_four .menu_toggle .hamburger span,
  .home_page_menu.menu_four .menu_toggle .hamburger-cross span {
    background: #6754e2;
  }
  .liquidity_provider_right_section {
    padding-top: 50px;
  }
  .announcement_section {
    height: 460px;
    background-size: cover;
  }
  .home_customer_billing {
    background-image: url("../../assets/img/home/home_billing_section_bg_mobile.png");
  }
  .home_cutomer_info .trusted_logo_bg {
    width: 80%;
    top: -12%;
  }
  .home_cutomer_info .client_customrow,
  .home_cutomer_info .client_leftarea {
    padding: 0px;
  }
  .trusted_description {
    max-width: 100%;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1600px) {
  .client_rightarea .payment_features_content {
    max-width: 100%;
    padding: 0 0 0 171px;
  }
}

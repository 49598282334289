.forex_hosting_banner_area {
  height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background-image: url("../../../assets/img/forex/forexBanner2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header_first-forex {
  font-family: Poppins;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 30px;
  color: #677294 !important;
  text-align: right;
  line-height: 30px !important;
}

.header_second {
  font-family: Poppins;
  font-weight: 600;
  font-size: 50px;
  line-height: 70px;
  /* or 140% */
  /* Black 1 */
  color: #051441;
}

.desc_first {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  max-width: 399px;
  /* Black 2 */
  color: #677294 !important;
}

.banner_button {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .forex_hosting_banner_area {
    background-image: url("../../../assets/img/forex/forex_banner_mobile.png");
    text-align: center;
    padding-top: 60px;
    align-items: center;
    height: 110vh;
    margin-top: 40px;
    display: block !important;
  }
  .temp {
    display: none !important;
  }
  .forex_text {
    margin: 35vh 0 30px 0 !important;
    text-align: center;
  }
  .header_first-forex {
    text-align: center !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .header_second {
    font-size: 28px !important;
    line-height: 38px !important;
    font-weight: 500 !important;
  }
  .forex_text .desc_first {
    color: #677294 !important;
  }
}

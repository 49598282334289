.forex-benefit-title {
  line-height: 54px;
  color: #051441;
}

.forex-benefit-wrapper {
  padding: 135px 0px 85px;
}

.bg_img_benefit {
  background-image: url("../../../assets/img/forex/benefit_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #fff;
}

.forex_benefit_crypto_item {
  height: 100%;
}

.forex_benefit_volitality .card_back_content,
.forex_benefit_volitality .card_front_content,
.forex-benefit-wrapper .forex_benefit_crypto_item .card_front_content,
.forex-benefit-wrapper .forex_benefit_crypto_item .card_back_content,
.forex-benefit-wrapper .forex_benefit_many_item .card_front_content,
.forex-benefit-wrapper .forex_benefit_many_item .card_back_content {
  padding: 20px 30px 20px 0px;
}

.forex_benefit_many_item .card_back_content .custom_wrap,
.forex_benefit_many_item .card_front_content .custom_wrap,
.forex_benefit_crypto_item .card_back_content .custom_wrap,
.forex_benefit_crypto_item .card_front_content .custom_wrap,
.forex_benefit_volitality .card_back_content .custom_wrap,
.forex_benefit_volitality .card_front_content .custom_wrap {
  margin: 0;
  padding: 0;
}

.forex-benefit-wrapper .custom_wrap {
  margin: 0;
}

.pr_0 {
  padding-right: 0px;
}

.pl_0 {
  padding-left: 0px;
}

@media (max-width: 767px) {
  .forex-benefit-title {
    font-size: 22px !important;
    line-height: 33px !important;
    font-weight: 500;
  }
  .bg_img_benefit {
    background-image: url("../../../assets/img/forex/benefit_bg_mobile.png");
  }
  .forex-benefit-wrapper {
    padding-top: 40px !important;
  }
  .forex_benefit_volitality .custom_wrap img,
  .forex_benefit_many_item .custom_wrap img {
    padding-top: 30px;
  }
  .forex_benefit_volitality .card_back_content,
  .forex_benefit_volitality .card_front_content,
  .forex-benefit-wrapper .forex_benefit_many_item .card_front_content,
  .forex-benefit-wrapper .forex_benefit_many_item .card_back_content {
    padding: 20px 0px 20px 15px;
  }
  .forex-benefit-wrapper .forex_benefit_item {
    margin-bottom: 30px;
  }
  .forex_benefit_volitality .card_front,
  .forex_benefit_volitality .card_back {
    min-height: 240px;
  }
}

.executor_title {
  padding: 0 0 20px;
}

.executor_subtitle {
  padding: 0 168px;
}

@media screen and (max-width: 767px) {
  .executor_subtitle {
    padding: 0 20px;
  }
}

.agency_featured_item .agency_featured_content .step-title {
  color: #051441;
  margin: 0 10% 20px 0;
}

.agency_featured_item .agency_featured_content .step-description {
  font-size: 16px !important;
  line-height: 24px !important;
}

.agency_featured_item .agency_featured_content .top-up-step-title {
  color: #051441;
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
}

.agency_featured_item .agency_featured_content .gaming-step-title {
  color: #051441;
  margin: 0 51% 20px 0;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 31px;
}

.agency_featured_item .agency_featured_content .gaming-step-description {
  font-size: 14px !important;
  line-height: 21px !important;
  max-width: 370px;
}

.agency_featured_item .agency_featured_content .paywithcrypto-step-description {
  max-width: 370px;
  font-size: 16px !important;
  line-height: 24px !important;
}

.agency_featured_item .agency_featured_content .top-up-step-description {
  font-size: 16px !important;
  line-height: 24px !important;
}

.agency_featured_content {
  max-width: 370px;
}

.get-started-btn {
  padding-top: 123px;
  padding-bottom: 120px;
}

.get-started-btn :hover {
  background: #7023ff;
  color: #ffffff;
}

.feature_title h3 {
  margin: 0 0 20px 0 !important;
}

.topUp-mobile-1 img {
  margin-left: -20px;
}

.topUp-mobile-2 img {
  margin-right: -20px;
}

.number {
  margin-left: -22px !important;
}

.mappingWrapper .mapping {
  position: absolute;
  width: 4%;
  height: 4.6%;
  right: 39%;
  /* background-color: red; */
  top: 38.6%;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .paycrypto_mobile_reverse {
    flex-direction: column-reverse !important;
  }

  .agency_featured_item .agency_featured_content .top-up-step-description,
  .top-up-step-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .paycrypto_mobile_reverse .agency_featured_img img {
    padding: 30px 0;
    border-bottom: 1px solid #ebeefa;
  }

  .paycrypto_mobile_reverse .agency_featured_content {
    margin-top: 0 !important;
  }
}

.pumapay_wallet_carousel {
  background-image: url("../../../assets//img//pumapay-app//background-wallet.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.pumapay_custom_indicators {
  margin: 28px;
}

.pumapay_custom_indicators li {
  background: #adb5cc;
  border-radius: 150px;
  width: 15px;
  height: 15px;
  border: 0px;
}

.pumapay_custom_indicators li.active {
  background: #7023ff;
  width: 42px;
}

.carousel-control-prev {
  left: -150px !important;
}

.carousel-control-next {
  right: -150px !important;
}

.wallet_carousel_bg {
  background-image: url("../../../assets//img//pumapay-app//background-wallet.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }

  .carousel-control-prev {
    left: 0 !important;
  }

  .carousel-control-next {
    right: 0 !important;
  }
}

.section_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 16px;
  color: #051441;
}

.m_a {
  margin: auto;
}

.section_desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;
}

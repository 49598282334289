.content-wrapper {
  justify-content: space-between;
}

.zero-const-title {
  line-height: 54px;
  color: #051441;
}

.try_it {
  z-index: unset !important;
  color: #007bff !important;
}

.solution_flexible .react-card-flipper .react-card-front .card_front_content,
.solution_flexible .react-card-flipper .react-card-back .card_back_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.solution_flexible .react-card-flipper .react-card-front .card_front,
.solution_flexible .react-card-flipper .react-card-back .card_back {
  min-height: 310px;
  display: flex;
  justify-content: center;
  align-content: center;
}
@media (max-width: 767px) {
  .solution_flexible .react-card-flipper .react-card-front .card_front,
  .solution_flexible .react-card-flipper .react-card-back .card_back {
    min-height: 450px;
  }
}

.claim_reward_section {
  background-image: url("../../../assets/img/liquidity-providers/provide-liquidity.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.claim_reward_detail {
  padding: 50px 0;
  margin: 50px 0;
}

.claim_reward_detail .title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  /* display: flex; */
  flex-direction: column;
}

.claim_reward_detail .sub_title {
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}

.claim_reward_detail .title .claim_reward_amount {
  color: #22d2fd;
}

@media screen and (max-width: 767px) {
  .claim_reward_section {
    background-image: url("../../../assets/img/liquidity-providers/provide-liquidity_mobile.png");
  }
  .claim_reward_detail {
    padding: 360px 0 20px;
    margin: 50px 0;
  }
  .claim_reward_detail .title {
    font-size: 30px;
    line-height: 45px;
  }
  .claim_reward_detail .sub_title {
    font-size: 24px;
    line-height: 36px;
    flex-direction: column;
    display: flex;
    padding: 30px 0;
  }
}

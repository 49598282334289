.gaming_banner,
.reward_banner {
  padding-top: 200px;
  padding-bottom: 280px;
  overflow: hidden;
  background-size: 100% 100%;
  height: inherit;
  background-repeat: no-repeat;
}
.gaming_banner {
  background-image: url("../../../assets/img/common/banner-backgroud.png");
}
.reward_banner {
  background-image: url("../../../assets/img/common/reward_banner_bg.png");
}

.pumapay_app_banner {
  background-image: url("../../../assets/img/common/banner-backgroud.png");
  padding-top: 200px;
  padding-bottom: 280px;
  overflow: hidden;
  background-size: 100% 100%;
  height: inherit;
  background-repeat: no-repeat;
}

.solutions_for_title,
.solutions_for_header,
.solutions_for_description {
  color: #ffffff;
}

.solutions_for_content {
  width: 100%;
  min-width: 540px;
}

.solutiom_for_sImage {
  position: relative;
  right: -110px;
}

.solutions_for_header_image_gaming {
  width: 100%;
}

.solutions_for_header {
  line-height: 70px;
  font-size: 50px;
  width: 118%;
}

.solutions_for_description {
  font-size: 18px;
}

.solutions_for_description_btn {
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.download-btn {
  background: #ffffff;
  border-radius: 4.01321px;
}

.download-btn-wallet {
  background-color: #ffffff;
  color: #051441;
  border-radius: 10px;
  border: unset;
}

.btn_header_banner_2 a {
  background-color: #ffffff;
}

@media (max-width: 1450px) {
  .gaming_banner,
  .reward_banner {
    background-size: 100% 100%;
    padding-bottom: 251px;
    padding-top: 150px;
  }
}

@media (max-width: 767.9px) {
  .solutions_for_header {
    width: 100%;
    line-height: 38px;
    font-size: 28px;
    letter-spacing: -0.02em;
    font-weight: 500;
    margin-bottom: 14px;
  }
  .solutions_for_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 14px;
  }
  .solutions_for_description {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 22px;
  }
  .solutiom_for_sImage {
    position: unset;
  }
  .gaming_banner,
  .reward_banner {
    background-size: cover;
    background-position: -216px -115px;
    padding-bottom: 60px;
    padding-top: 120px;
  }
  .pumapay_app_banner {
    background-size: cover;
    background-position: -355px -70px;
    padding-bottom: 60px;
    padding-top: 120px;
  }
  .solutions_for_content {
    width: 100%;
    text-align: center;
    padding: 0 15px;
    min-width: 100%;
  }
}

.slide_bg {
  background: #f4fcfc;
  padding: 113px 0 100px 0;
}

.slide_title {
  font-weight: normal;
  font-size: 30px;
  line-height: 45px;
  color: #051441;
  max-width: 416px;
  margin: 0 auto;
  margin-bottom: 88px;
}

@media (max-width: 767px) {
  .slide_title {
    font-size: 22px;
    line-height: 33px;
  }
}

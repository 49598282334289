.pt_10 {
  padding-top: 10%;
}

.create-billing-wrapper {
  position: relative;
  bottom: -50px;
  margin-top: 54px;
}

.create_billing_model {
  border-radius: 6px;
}

.billing_content {
  text-align: center;
  z-index: 9;
  padding: 40px 15px;
  top: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.billing_content_bg_simple {
  background-image: url("../../../assets/img/footerbanner/createBMBG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.billing_content_bg_dark {
  background-image: url("../../../assets/img/footerbanner/createBMBG1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.billing_content_bg_pumapay_app {
  background-image: url("../../../assets/img/footerbanner/createBMBG2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.billing_content_bg_telegram {
  display: block;
  padding-left: 10%;
  text-align: left;
  background-image: url("../../../assets/img/footerbanner/telegram_banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.billing_content_bg_dark_telegram {
  display: block;
  padding-left: 10%;
  text-align: left;
  background-image: url("../../../assets/img/footerbanner/telegram_banner_dark.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 20px;
}

.subscription {
  margin: 2% 28%;
}

.home {
  margin: 0% !important;
}

.topup {
  margin: 2% 25%;
}

.topup_des {
  margin: auto 31%;
}

.other_billing {
  margin: 1% 30% !important;
}

.other_billing_des {
  margin: 1% 12% !important;
}

.accept_acrypto {
  max-width: 348px;
}

.accept_acrypto_des {
  max-width: 535px;
}

.gaming {
  margin: 1% 30% !important;
}

.gaming_des {
  margin: 1% 12% !important;
}

.forex {
  margin: 1% 32% !important;
}

.forex_des {
  margin: 1% 12% !important;
}

.billing_content .title {
  font-family: "Poppins";
  color: #fff;
  margin-bottom: 12px;
}

.billing_content p {
  font-family: "Poppins";
  color: #fbfbfd;
  margin-bottom: 26px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}

.create-billing-img {
  width: 100%;
  border-radius: 6px;
}

.billing_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #5e2ced;
  background: #fff;
  box-shadow: 0px 20px 30px 0px rgb(12 0 46 / 10%);
  border-radius: 4px;
  padding: 16px 38px;
  border: 1px solid #fff;
  transition: all 0.2s linear;
  cursor: pointer;
}

.billing_btn:hover {
  color: #5e2ced;
}

.banner_button {
  background-color: #fff;
}

.custom_banner :hover {
  background-color: #fff;
  color: #6754e2;
}

.custom_banner {
  display: flex;
  justify-content: center;
}

.tele_icon {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .create-billing-img {
    height: 305px;
    object-fit: cover;
  }
  .solution_footer_banner .billing_content {
    padding: 40px 15px !important;
  }
}

@media (max-width: 767px) {
  .create-billing-img {
    height: 330px;
    object-fit: cover;
  }
  .billing_content {
    padding: 30px 20px;
  }
  .billing_content p {
    font-size: 13px;
    line-height: 19px;
  }
}

@media (max-width: 320px) {
  .create-billing-img {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .billing_content_bg_dark_telegram {
    background-image: url("../../../assets/img/footerbanner/footer_banner_dark_telegram_mobile.png");
    text-align: center;
  }
  .topup {
    margin: 15px 10px;
  }
  .create-billing-wrapper {
    margin-top: 0;
  }

  .billing_content .title {
    font-size: 24px !important;
    margin: 20px 15px !important;
  }

  .billing_content_bg_pumapay_app .title {
    margin: 20px 10px !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 33px !important;
  }

  .billing_content_bg_pumapay_app .pumapay_app_description {
    max-width: 255px;
  }

  .billing_bg {
    padding-top: 0 !important;
  }
  .tele_icon {
    display: inline-block;
  }
  .gaming {
    margin: 20px 0 !important;
  }
  .gaming_des {
    margin-bottom: 20px !important;
  }
  .billing_content_bg_dark h2 {
    margin: 20px 0 !important;
  }
  .billing_content_bg_dark {
    background-size: cover;
    background-position: bottom;
  }

  .billing_content_bg_simple {
    background-size: cover;
    background-position: bottom;
  }
  .billing_content_bg_pumapay_app {
    background-size: cover;
    background-position: right;
  }
}

.top_desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  /* Black 2 */
  color: #677294;
}

.list_custom {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #051441;
}

.automated_title {
  margin: auto !important;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

@media (max-width: 767px) {
  .list_custom {
    text-align: left;
    font-size: 14px;
  }
  .automated_title {
    font-size: 22px;
    line-height: 33px;
    margin: 0 20px !important;
  }
  .forex_automated_section .container {
    padding: 0 20px !important;
  }
  .forex_automated_section .agency_featured_item {
    margin: 0;
    flex-direction: column-reverse;
  }
  .forex_automated_section .features_info .agency_featured_content {
    margin: 40px 0;
  }
  .forex_automated_section .features_info .agency_featured_content ol {
    padding-inline-start: 10px;
  }
  .forex_automated_section
    .features_info
    .agency_featured_content
    ol
    .list_custom {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #051441;
  }
}

.info_page .solutions_for_adult_section {
  background: url(../../assets/img/info/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.info_page .solutions_for_adult_header,
.info_page .solutions_for_adult_description {
  color: #fff;
}

.info_page .solutions_for_adult_description {
  font-size: 18px;
  line-height: 27px;
  padding-right: 40px;
}

.info_page .solutions_for_adult_content {
  width: 40%;
}

.info_page .solutions_for_adult_section {
  height: 870px;
}

.info_page .solutions_for_adult_button a {
  width: 220px;
  background-color: #fff;
  font-weight: 500;
}

.info_page .solutions_for_adult_button a:hover {
  color: #fff;
  background-color: #7023ff;
}

.info_pumapay_journey_header {
  font-size: 36px;
  line-height: 54px;
  color: #051441;
  padding: 100px 0px;
  background: linear-gradient(
    180deg,
    #f4fcfc 0%,
    rgba(244, 252, 252, 0) 100.09%
  );
}

.info_pumapay_journey img {
  width: 90%;
}

@media (max-width: 768px) {
  .info_page .solutions_for_adult_section {
    background: url(../../assets/img/info/mobile/header-bg.png);
    padding: 100px 30px 60px 30px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .info_page .solutions_for_adult_content {
    width: 100%;
  }
  .info_page .solutions_for_adult_button a {
    color: #7023ff;
  }
}

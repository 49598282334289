.item img {
  width: 100%;
}

.userfeed-back-item {
  text-align: center;
  padding: 30px;
  box-shadow: 1px 30px 60px rgb(55 125 162 / 20%);
  border-radius: 32px;
  background: #fafbff;
  position: relative;
  margin: 15px;
}

.ratings {
  height: 32px;
  width: auto !important;
  margin: 40px auto;
  display: table !important;
}

.googlePlay {
  height: 52px;
  width: auto !important;
  display: table;
  margin: auto;
}

.userfeed-p {
  min-height: 300px;
}

.userfeed-border {
  background: #fcd010;
  height: 6px;
  width: 180px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .userfeed-back-item {
    margin: 40px;
  }

  .feedback-title {
    margin: 50px auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    width: 80%;
    text-align: center;
  }

  .feed-back-description {
    line-height: 25px;
  }

  .feed-back-name {
    font-weight: 600;
    font-size: 23.3825px;
    line-height: 35px;
    color: #051441;
  }
}

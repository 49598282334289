.error_page .nav-item .nav-link,
.error_page .nav-item:hover .nav-link {
  color: #051441;
}

.navbar_fixed .no_filter img {
  filter: inherit;
}

@media screen and (max-width: 786px) {
  .error .menu_toggle .hamburger span {
    background: #6754e2;
  }
}

.payment_wrapper {
  background: linear-gradient(180deg, #f4fcfc 0%, rgba(244, 252, 252, 0) 100%);
  padding-top: 80px;
  padding-bottom: 91px;
  margin-bottom: 23px;
}

.payment-title {
  color: #051441;
}

.forex_des_color {
  max-width: 299px;
  color: #051441;
}

.gaming_des_color {
  color: #677294;
}

.pl_100 {
  padding-left: 100px;
}

.forex_action_btn_web {
  display: block;
}

.forex_action_btn_mobile {
  display: none !important;
}

@media (max-width: 767px) {
  .payment-title {
    font-size: 22px;
    line-height: 33px;
    padding: 0px 10px 15px;
    margin: 0 !important;
  }
  .gaming_payment_section {
    padding-left: 15px !important;
    text-align: center;
    margin-top: 40px;
  }
  .gaming_payment_section .gaming_payment_content {
    padding-right: 0 !important;
    text-align: center;
  }
  .gaming_payment_title_des_mobile {
    display: block;
  }
  .gaming_payment_section .action_btn {
    display: none !important;
  }
  .gaming_payment_title_des {
    display: none;
  }
  .gaming_payment_title_des_mobile .gaming_des_color {
    font-size: 14px;
    line-height: 21px;
  }
  .gaming_payment {
    margin-bottom: 40px;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #677294;
    max-width: 322px;
  }
  .custom_api_detail {
    text-align: center;
  }
  .custom_api_detail .title {
    margin-top: 20px;
  }
  .payment_wrapper {
    padding: 50px 0;
    margin: 0;
  }
  .payment_wrapper .container:after {
    content: "";
    border-bottom: 1px solid #ebeefa;
    left: 5%;
    width: 90%;
    position: absolute;
    margin-top: 40px;
  }
  .payment_wrapper .payment-title p {
    font-size: 14px;
    line-height: 21px;
  }
  .forex_payment_content {
    flex-direction: column-reverse;
  }
  .forex_payment_img {
    padding: 20px 0px 0px;
  }
  .forex_des_color {
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
    color: #677294;
  }
  .forex_action_btn_mobile {
    display: inline-flex !important;
    padding: 30px 0px 0;
  }
  .agency_featured_area {
    padding: 40px 0;
  }
}

.btn_get_statrted {
  padding-left: 70px !important;
}

.solotion {
  height: 500px;
}

.solotion .hosting_content {
  padding-bottom: 69px;
}

.solution_service_section {
  padding: 100px 0px 49px;
}

.solution_service_section .p_service_item p {
  min-width: 278px;
}

.solution_service_section .try_it {
  color: #7023ff !important;
}

.solution_service_section .try_it:before {
  display: none;
}

.solution_ecosystem {
  padding: 70px ​0px 80px;
  margin-bottom: 0;
}

.solution_ecosystem .h_action_content {
  padding: 0 0 50px;
}

.solution_simple {
  padding: 118px 0px 96px;
}

.solution_simple .h_features_content {
  margin-left: 50px;
  padding: 0px;
}

.solution_simple .item_detail .h_features_item,
.solution_fast .item_detail .h_features_item,
.solution_flexible .item_detail .h_features_item,
.solution_risk_free .item_detail .h_features_item {
  padding: 20px 30px;
  max-width: 480px;
}

.solution_simple p a {
  color: #7023ff;
}

.solution_fast {
  padding-bottom: 90px;
}

.solution_flexible {
  padding-bottom: 90px;
}

.solution_flexible .item_detail {
  padding: 0;
}

.solution_carousel {
  padding: 95px 0px;
}

.solution_footer_banner .billing_content {
  padding: 55px 305px;
}

.solution_footer_banner .billing_content:lang(ja) {
  padding: 55px 270px;
}

.solution_footer_banner .create-billing-wrapper {
  margin-top: 0;
}

.h_action_content p {
  color: #677294;
}

.h_head {
  font-family: "Poppins", sans-serif;
  color: #051441;
}

.solution_simple .card_flip .card_front,
.solution_simple .card_flip .card_back,
.solution_fast .card_flip .card_front,
.solution_fast .card_flip .card_back,
.solution_risk_free .card_flip .card_front,
.solution_risk_free .card_flip .card_back {
  height: 150px;
}

@media screen and (max-width: 767px) {
  body .hosting_banner_area.solotion {
    height: auto !important;
    padding: 100px 0px 60px;
  }
  .hosting_banner_area.solotion .solution_title {
    font-size: 28px;
    line-height: 38px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-bottom: 30px;
  }
  .hosting_banner_area.solotion p {
    font-size: 14px;
    line-height: 21px;
  }
  .hosting_banner_area.solotion .hosting_content {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .solution_service_section {
    padding: 50px 0;
  }
  .solution_service_section h2 {
    font-size: 22px;
    line-height: 33px;
    display: flex;
    flex-direction: column;
  }
  .solution_service_section .commitment_item2 {
    text-align: right;
  }
  .solution_service_section .p_service_item p,
  .custom_subbanner_p {
    font-size: 13px;
    line-height: 19.5px;
  }
  .solution_service_section .p_service_item {
    margin-bottom: 39px;
  }
  .solution_service_section .p_service_item h5 {
    font-size: 18px;
    line-height: 27px;
  }
  .solution_service_section .agency_service_item .item-description a {
    color: #7023ff !important;
    display: flex;
    text-decoration: none !important;
  }
  .solution_service_section .agency_service_item .item-description a:before {
    display: none;
  }
  .solution_service_section .p_service_item:last-child {
    margin-bottom: 0;
  }
  .solution_ecosystem {
    padding: 40px 0;
  }
  .custom_subbanner_h {
    font-size: 22px;
    line-height: 33px !important;
    display: flex;
    flex-direction: column;
  }
  section .container {
    padding: 0px 25px !important;
  }
  .solution_simple {
    padding: 80px 0px 25px;
  }
  .solution_simple .h_features_content,
  .solution_flexible .h_features_content {
    margin: 0;
  }
  .solution_simple .h_features_content .h_features_item,
  .solution_fast .h_features_content .h_features_item,
  .solution_flexible .h_features_content .h_features_item,
  .solution_risk_free .h_features_content .h_features_item {
    padding-right: 0;
  }
  .solution_simple .hosting_title,
  .solution_fast .hosting_title,
  .solution_flexible .hosting_title,
  .solution_risk_free .hosting_title {
    margin: 0;
  }
  .solution_simple .h_head,
  .solution_fast .h_head,
  .solution_flexible .h_head,
  .solution_risk_free .h_head {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }
  .solution_simple p,
  .solution_fast p,
  .solution_flexible p,
  .solution_risk_free p {
    font-size: 13px;
    line-height: 19.5px;
  }
  .solution_simple .media.h_features_item,
  .solution_flexible .media.h_features_item {
    margin-top: 20px;
  }
  .solution_simple .custom_section_h,
  .solution_fast .custom_section_h,
  .solution_flexible .custom_section_h,
  .solution_risk_free .custom_section_h {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin: 0;
  }
  .solution_simple .h_features_content {
    margin-top: 30px;
  }
  .solution_flexible .h_features_content .h_features_item h4 {
    min-width: 100%;
  }
  .solution_risk_free .h_features_content div:nth-child(4),
  .solution_risk_free .h_features_content div:nth-child(5) {
    padding: 0;
  }
  .solution_risk_free {
    padding: 25px 0 100px;
  }
  .solution_flexible .h_features_content {
    margin-top: 50px;
  }
  .solution_carousel {
    padding: 50px 0 !important;
    margin: 0 !important;
  }
  .solution_carousel h1 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
  }
  .carousel_bg ol {
    margin-top: 15px;
  }
  .slide .custom_indicators li {
    width: 5px;
    height: 5px;
  }
  .slide .custom_indicators li.active {
    width: 16px;
  }
  .solution_footer_banner .billing_content {
    padding: 70px 30px;
  }
  .solution_footer_banner .billing_content_bg_simple {
    border-radius: 20px;
  }
  .solution_footer_banner .billing_content .title {
    font-size: 22px !important;
    line-height: 33px;
  }
  .solution_simple .container .item_detail,
  .solution_fast .container .item_detail,
  .solution_risk_free .container .item_detail,
  .solution_flexible .container .item_detail {
    flex-direction: column-reverse !important;
  }
  .solution_simple .container .h_features_img,
  .solution_fast .container .h_features_img,
  .solution_risk_free .container .h_features_img,
  .solution_flexible .container .h_features_img {
    padding-top: 20px;
  }
  .solution_simple:after,
  .solution_fast:after,
  .solution_flexible:after {
    content: "";
    border-bottom: 1px solid #ebeefa;
    left: 15px;
    width: 90%;
    position: absolute;
    margin-top: 30px;
  }
  .solution_fast,
  .solution_flexible {
    padding: 25px 0;
  }
  .solution_simple .card_flip .card_front,
  .solution_simple .card_flip .card_back,
  .solution_fast .card_flip .card_front,
  .solution_fast .card_flip .card_back,
  .solution_risk_free .card_flip .card_front,
  .solution_risk_free .card_flip .card_back {
    height: 200px;
  }
}

.learn-more-btn {
  color: #222d39;
  margin-left: 30px;
  font-size: 14px;
  align-items: center;
}

.f_700 {
  font-weight: 700;
}

.reward_liquidity_provider .section1_h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  color: #051441;
}

.reward_liquidity_provider_detail img {
  padding-right: 1rem;
}
.reward_liquidity_provider_detail .section1_p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #677294;
}

.reward_liquidity_provider_detail .section2_p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 45px;
  color: #677294;
}

.reward_liquidity_provider_detail .text-purple-blue {
  color: #7023ff;
  padding: 0px;
}

.reward_liquidity_provider_detail .section1_btn {
  width: 400px;
  max-width: 100%;
  border: 1px solid #5956fe;
}

.btn_join {
  width: 200px !important;
}

.deposit_liquidity {
  height: 50px;
  line-height: 30px;
  vertical-align: top;
  font-size: 14px;
  font-weight: 500;
}

.deposit_liquidity a {
  color: #7023ff;
}

.subHeaderRewards {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #677294;
}

@media screen and (max-width: 767px) {
  .reward_liquidity_provider .section1_h1 {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    padding: 0px 44px 30px;
  }
  .reward_liquidity_provider_detail .section1_p {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .reward_liquidity_provider_detail .section2_p {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  .deposit_liquidity {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    padding: 0;
  }
  .reward_liquidity_provider_detail img {
    padding-right: 0;
  }
  .reward_liquidity_provider_detail .disclaimer {
    text-align: left;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pp-btn-outline-primary {
  color: #7023ff;
  border-color: #7023ff;
}

/* Puma Pay Css prefixed with pp- */
.pp-btn {
  width: 180px;
  height: 50px;
  line-height: 36px;
}
.pp-btn-outline-primary:hover {
  color: #fff;
  background-color: #7023ff;
  border-color: #7023ff;
}

.f_size_20 {
  font-size: 20px;
}

.f_size_36 {
  font-size: 36px;
}

.l_height70 {
  line-height: 70px;
}

.l_height27 {
  line-height: 27px;
}

.l_height33 {
  line-height: 33px;
}

.l_height21 {
  line-height: 21px;
}

.l_height24 {
  line-height: 24px;
}

.l_height54 {
  line-height: 54px;
}

.f_size_36 {
  font-size: 36px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_33 {
  margin-bottom: 33px;
}

.mb_24 {
  margin-bottom: 24px;
}

.mb_80 {
  margin-bottom: 80px !important;
}

.mt_90 {
  margin-top: 90px !important;
}

.mr_20 {
  margin-right: 20px;
}

.ml_20 {
  margin-left: 20px;
}

.mt_64 {
  margin-top: 64px;
}

.l_height40 {
  line-height: 40px;
}

.l_height39 {
  line-height: 39px;
}

.email_anchor {
  color: #677294;
}

.pb_70 {
  padding-bottom: 70px;
}

.pl_60 {
  padding-left: 60px;
}

.pr_86 {
  padding-right: 86px;
}

.pr_165 {
  padding-right: 165px;
}

.grecaptcha-badge {
  visibility: hidden;
}

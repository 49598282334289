.custom_text_section {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 10px;
}

.payment_bg {
  background-image: url("../../../assets/img/otherbilling/payment_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.agency_featured_area > .container > h2 {
  margin: auto 25%;
}

.agency_featured_item_four h1 {
  margin-left: 22%;
}

.agency_featured_item_one p {
  padding-right: 35%;
}

.agency_featured_item_four p {
  padding: 0% 22%;
}

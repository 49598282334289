.agency_featured_item .agency_featured_content .step-title {
  color: #051441;
}

.agency_featured_item .agency_featured_content .step-description {
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #677294;
}

.forex_carousel_title {
  margin: 20px 0 20px 0 !important;
}

.forex_carousel_desc {
  padding-left: 0 !important;
}

@media (max-width: 767px) {
  .custom_caraousel {
    text-align: center !important;
  }
  .forex_carousel_desc {
    padding: 0 !important;
  }
}

.outlet_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  margin-top: 50px;
  margin-bottom: 15px;
  /* identical to box height */

  text-align: center;

  /* Black 1 */

  color: #051441;
}

.outlet_desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  /* Black 2 */

  color: #677294;
}

.bg_outlet {
  padding: 80px 0;
  background-image: url("../../../assets/img/paywithcrypto//bg_outlet.png");
}

.color_link {
  color: #6754e2;
}

.item-title {
  color: #051441;
}

.item-description {
  font-size: 16px;
  line-height: 24px;
  color: #677294;
}

.liquidity_item_title .item-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.liquidity_item_title .item-description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  max-width: 276px;
}

.custom_wrap {
  display: flex;
  margin-bottom: 60px;
}

.benefit .item-description {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #677294;
  padding-right: 70px;
}

.benefit img {
  margin: -20px;
}

@media (max-width: 767px) {
  .reverse_wrap {
    flex-direction: row-reverse;
    display: flex;
  }
  .custom_wrap img {
    width: 72px;
  }

  .benefit .item-title {
    font-size: 25px;
  }

  .benefit .item-description {
    font-size: 15px;
    padding-right: 0;
  }

  .custom_wrap .item-title,
  .custom_wrap .item-description {
    text-align: left;
  }

  .reverse_wrap .item-title,
  .reverse_wrap .item-description {
    text-align: right;
  }
}

.liquidity_banner .solutions_for_content h1 {
  width: 100%;
}

.liquidity_banner .btn_header_banner_2 {
  display: flex;
}

.liquidity_banner .learn-more-btn {
  background-color: transparent;
  color: white;
}

@media screen and (max-width: 767px) {
  .liquidity_banner .btn_header_banner_2 {
    flex-direction: column;
    align-items: center;
  }
  .liquidity_banner .learn-more-btn {
    margin: 20px;
  }
}

.griditem_p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #677294;
  text-align: center;
}

.griditem_h {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #051441;
  margin-bottom: 13px;
}

.grid_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.detail {
  background: transparent;
}

.custom_detail {
  margin-bottom: 74px;
}

.custom_detail .griditem_p {
  max-width: 484px;
  margin: 0 auto;
}

.frequency_detail .griditem_p {
  max-width: 292px;
  margin: 0 auto;
}

.custom_content p {
  margin-bottom: 36px !important;
}

.trial_detail {
  padding: 0 !important;
}

.trial_detail p {
  margin-bottom: 100px !important;
}

@media (max-width: 767px) {
  .grid_title {
    font-size: 22px;
    line-height: 33px;
  }

  .grid_desc {
    font-size: 13px !important;
    line-height: 19px !important;
  }
}
